<template>
  <div class="item">
    <div class="item-title">
      <i class="iconfont icon-zhengpinbaozhangduigou"></i>合同文件
    </div>
    <div class="offline-main">
      <upload
        :preview="preview"
        mode="contract"
        format="pdf/ppt/pptx/doc/docx/jpg/jpeg/png/svg/gif"
        ref="upload"
        :fileName.sync="filesName"
        @success="upSuccess"
        :filelist="filelists"
        :fileData.sync="fileData"
        @removeFile="removeFile"
        :keyName="keyName"
      />
      <p class="note" v-if="!preview">
        *若合同内容有变更，请删除废弃的合同文件
      </p>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    keyName: {
      default: "",
      type: String | Number,
    },
    value: {
      default: "",
      type: String,
    },
    preview: {
      default: false,
      type: Boolean|Number,
    },
    filelist: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  computed: {
    filelists: {
      get() {
        return this.filelist;
      },
      set(val) {
        this.$emit("update:filelist", val);
      },
    },
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      bizKey: "",
      filesName: "",
      fileData: [],
    };
  },
  methods: {
    clear() {
      this.$refs.upload.clear();
    },
    removeFile(item) {
      this.filelists = this.filelists.map((items) => {
        if (items.id == item.id) {
          items.status = 1;
        }
        return items;
      });
    },
    submit() {
      if (this.filesName) {
        this.$refs.upload.submit();
      } else {
        this.upSuccess();
      }
    },
    upSuccess(files) {
      this.$emit("success", files);
    },
  },
};
</script>

<template>
  <div class="win" v-if="detail.yzViewShopInfo">
    <div class="content">
      <div class="win-main">
        <div class="tip">
          <span class="el-icon-bell"></span>
          <span>{{ detail.yzViewShopInfo.cropName }}</span> 为雇主解决了需求
        </div>
        <div class="b">
          <el-image :src="detail.yzViewShopInfo.logo" fit="cover" lazy></el-image>
          <div class="info">
            <div class="title">
              {{ detail.yzViewShopInfo.cropName }}
              <i class="iconfont icon-auth"></i>
              <span
                class="common-label"
                v-for="items in detail.yzViewShopInfo.yzCompanyAuthItems"
                :key="items"
                >{{ yzCompanyAuthItemsJson[items] }}</span
              >
              <span class="site">
                <i class="iconfont icon-dizhi1"></i>
                {{ detail.yzViewShopInfo.areaName }}
              </span>
            </div>
            <div class="desc">
              主营：{{ detail.yzViewShopInfo.hobbyname }}。
            </div>
            <div class="else">
              <span
                >综合评分：<font>{{
                  detail.yzViewShopInfo.yzUserInfoCount.multipleScore
                }}</font></span
              ><font v-if="!isWap">|</font>
              <span
                >累计成交金额：<font
                  >￥{{ detail.yzViewShopInfo.alerdyServiceTotalMoney }}</font
                ></span
              >
              <font v-if="!isWap">|</font>
              <span
                >好评率：<font
                  >{{ detail.yzViewShopInfo.goodRate }}%</font
                ></span
              ><font v-if="!isWap">|</font>
              <span
                >项目完成率：<font
                  >{{ detail.yzViewShopInfo.yzUserInfoCount.projectRate }}%</font
                ></span
              >
            </div>
          </div>
          <div class="btn">
            <p class="common-btn" @click="goDetail(detail.yzViewShopInfo)">
              进店看看
            </p>
            <p class="common-btn" @click="goChat(detail.yzViewShopInfo)">
              咨询TA
            </p>
          </div>
          <div class="icon">
            <img src="@/assets/images/public/icon_yzb.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: {},
    },
    yzCompanyAuthItemsJson: {
      default: {},
      type: Object,
    },
  },
  computed:{
  },
  data() {
    return {
      isWap:false
    };
  },
  mounted(){
    this.isWap = $(window).width()<1000;
  },
  methods: {
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shop-${item.companyId}.html`;
    },
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.huixianggongye.com/f/contactKefu?company_id=" + item.companyId;
    },
  },
};
</script>
<template>
  <div class="servers-deteil">
    <supplier-menu :active="2" :banner="false" />
    <div class="flow">
      <div class="content">
        <div class="flow-main">
          <div class="title">
            <h3>在线商务，保证双方权益，可视化生产流程，助力订单高效完成</h3>
            <p>交易保证 <span></span> 高效沟通 <span></span> 协同生产 <span></span> 品质保证 <span></span> 问题追溯</p>
          </div>
          <div class="list flex_b flex_align_c">
            <div
              class="item flex_b flex_align_c"
              v-for="(item, index) in flowList"
              :key="item.id"
              style="background: none"
            >
              <div class="info">
                <div class="num flex_c flex_align_c">
                  <span>{{ index + 1 }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
              <div class="next">------->></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <win
      :detail="detail"
      @inits="inits"
      :yzCompanyAuthItemsJson="yzCompanyAuthItemsJson"
    />
    <detail-info
      ref="detailInfo"
      :detail="detail"
      :overDate="overDate"
      :isOver="isOver"
      :yzTaskSteps="yzTaskSteps"
      @inits="inits"
    />
    <step
      :detail="detail"
      :overDate="overDate2"
      :isOver="isOver2"
      :yzTaskSteps="yzTaskSteps"
      :steps="steps"
      ref="detailStep"
    />
    <offer :detail="detail" @bench="bench" @inits="inits" />
    <detail-tabs
      ref="detailTabs"
      @inits="inits"
      :active.sync="active"
      :detail.sync="detail"
    />
    <detail-supplier-list
      ref="detailSupplierList"
      @inits="inits"
      :yzCompanyAuthItemsJson="yzCompanyAuthItemsJson"
      :list="listYzTaskEvaluation"
      :detail="detail"
      :taskUser="taskUser"
    />
    <div class="hot-service">
      <div class="content">
        <div class="common-title">
          <h3>推荐需求 <span>DEMAND</span></h3>
        </div>
        <div class="hot-service-main flex_b">
          <div class="main-l servers-list-main flex_b">
            <servers-item
              :item="item"
              :yzTaskStausJson="yzTaskStausJson"
              :yzTaskTagsJson="yzTaskTagsJson"
              v-for="item in datalist"
              :key="item.id"
            />
          </div>
          <div class="main-r">
            <ad :maxLen="2" />
          </div>
        </div>
      </div>
    </div>
    <hot mode="servers" itemLen="4" />
    <chat :detail="detail" />
  </div>
</template>
<script>
import supplierMenu from "@/components/servers/supplier-menu";
import serversItem from "@/components/servers/servers-item";
import ad from "@/components/servers/ad";
import hot from "@/components/servers/hot";
import win from "@/components/service/win";
import detailInfo from "@/components/service/detail-info";
import step from "@/components/service/step";
import offer from "@/components/service/offer";
import chat from "@/components/service/chat";
import detailTabs from "@/components/service/detail-tabs";
import detailSupplierList from "@/components/service/detail-supplier-list";
export default {
  components: {
    supplierMenu,
    serversItem,
    ad,
    hot,
    win,
    detailInfo,
    step,
    offer,
    detailTabs,
    detailSupplierList,
    chat,
  },
  data() {
    return {
      active: 1,
      upVisible: false,
      flowList: [
        {
          id: 1,
          title: "发布需求",
        },
        {
          id: 2,
          title: "专业顾问服务",
        },
        {
          id: 3,
          title: "极速报价",
        },
        {
          id: 4,
          title: "选厂签单",
        },
        {
          id: 5,
          title: "协同生产",
        },
        {
          id: 6,
          title: "发货验收",
        },
        {
          id: 7,
          title: "交易成功",
        },
      ],
      detail: {
        yzTaskDataStatistics: {},
        adviserUser: {},
        taskUser: {},
        yzTaskData: {},
        selectShoperYzTaskEvaluation: { evaluationUserId: "" },
      },
      taskUser: {},
      datalist: [],
      yzTaskStausJson: {},
      overDate: { d: 0, h: 0, m: 0, s: 0 },
      overDate2: { d: 0, h: 0, m: 0, s: 0 },
      overDate2Num: 0,
      isOver: false,
      isOver2: false,
      yzTaskTagsJson: {},
      listYzTaskEvaluation: [],
      yzCompanyAuthItemsJson: {},
      isWap: false,
      yzTaskSteps: 0,
      steps: [],
    };
  },
  mounted() {
    this.isWap = $(window).width() < 1000;
    this.getHotService();
    this.inits();
    this.$parent.finish ? this.$parent.finish() : null;
  },
  methods: {
    getYzTaskSteps() {
      //需求状态
      this.$sapi
        .get("/api/v1/getDictDataList-yz_task_step.json")
        .then((res) => {
          this.steps = res.map((item) => {
            return {
              id: item.dictValue,
              title: item.dictLabel,
              date: "",
            };
          });
          this.$api
            .get("/api/v1/selectYzFrontStepLogsById?taskId=" + this.detail.id)
            .then((res) => {
              this.steps = this.steps.map((item, index) => {
                let obj = res.data.find((items) => items.id == item.id);
                let arr = obj ? obj.create_date.split(":") : [];
                item.date = obj ? `${arr[0]}:${arr[1]}` : "";
                if (obj) {
                  this.yzTaskSteps = index + 1;
                }
                return item;
              });
              let userInfo = this.$store.state.userInfo;
              if (userInfo) {
                let obj = this.detail.listYzTaskEvaluation.find(
                  (item) => item.updateBy == userInfo.userCode
                );
                let isMy = userInfo.userCode == this.detail.taskUser.userCode;
                let isShop = this.detail.selectShoperYzTaskEvaluation
                  ? userInfo.userCode ==
                    this.detail.selectShoperYzTaskEvaluation.evaluationUserId
                  : false;
                if (this.yzTaskSteps == 2 && obj && !obj.singilePrice) {
                  //是服务商，且已经参与需求，且没有报价，跳转报价模块
                  $("body,html").animate(
                    { scrollTop: $(".step").eq(0).offset().top - 150 },
                    300
                  );
                } else if (
                  (this.yzTaskSteps == 2 || this.yzTaskSteps == 3) &&
                  obj &&
                  obj.singilePrice
                ) {
                  //是服务商，且已经参与需求，且已经报价，跳转至服务商列表
                  $("body,html").animate(
                    { scrollTop: $(".detail-tabs").eq(0).offset().top - 150 },
                    300
                  );
                } else if (
                  (this.yzTaskSteps == 2 || this.yzTaskSteps == 3) &&
                  isMy
                ) {
                  //是雇主，且招标阶段
                  $("body,html").animate(
                    { scrollTop: $(".detail-tabs").eq(0).offset().top - 150 },
                    300
                  );
                } else if (this.yzTaskSteps >= 4 && (isMy || isShop)) {
                  //工作台阶段
                  $("body,html").animate(
                    { scrollTop: $(".detail-tabs").eq(0).offset().top - 150 },
                    300
                  );
                }
              }
            });
        });
    },
    getData() {
      this.$api
        .get("/api/v1/getYzTaskMain", { params: { id: this.$route.query.id } })
        .then((res) => {
          this.detail = res.data;
          this.getYzTaskSteps();
          this.taskUser = this.detail.taskUser || {};
          if (this.detail.yzCompanyAuthItems) {
            this.detail.yzCompanyAuthItems = this.detail.yzCompanyAuthItems.split(
              ","
            );
          }
          if (this.detail.listYzTaskEvaluation) {
            this.detail.listYzTaskEvaluation = this.detail.listYzTaskEvaluation.map(
              (item) => {
                console.log(JSON.stringify(item));
                if (
                  item.yzViewShopInfo &&
                  item.yzViewShopInfo.yzCompanyAuthItems
                ) {
                  item.yzViewShopInfo.yzCompanyAuthItems = item.yzViewShopInfo.yzCompanyAuthItems.split(
                    ","
                  );
                } else if (!item.yzViewShopInfo) {
                  item.yzViewShopInfo = { yzCompanyAuthItems: [] };
                }
                return item;
              }
            );
          }
          if (this.detail.yzViewShopInfo) {
            this.detail.yzViewShopInfo.yzCompanyAuthItems = this.detail.yzViewShopInfo.yzCompanyAuthItems.split(
              ","
            );
          }
          this.listYzTaskEvaluation = this.detail.listYzTaskEvaluation;
          //更新浏览人数
          this.$api
            .post(
              "/api/v1/updateViewCountAddOne",
              this.$common.getFormData({
                id: this.detail.id,
              })
            )
            .then((res) => {
              console.log(res);
            });
          console.log(res.data);
          this.$nextTick(() => {
            this.$refs.detailSupplierList.inits();
            this.$refs.detailStep.inits();
            this.$refs.detailInfo.goResize();
            this.$refs.detailTabs.inits(true);
            let userInfo = this.$store.state.userInfo;
            if (userInfo) {
              let obj = this.detail.listYzTaskEvaluation.find(
                (item) => item.updateBy == userInfo.userCode
              );
              if (obj) {
                this.overDate2Num = obj.overDate;
                this.getOverDate2();
              } else {
                this.isOver2 = true;
              }
            }
          });
          this.getOverDate();
        });
    },
    inits() {
      this.$sapi
        .get("/api/v1/getDictDataList-yz_company_auth_items.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzCompanyAuthItemsJson[item.dictValue] = item.dictLabel;
          });
          this.getData();
        });
    },
    bench() {
      $("body,html").animate({
        scrollTop: $(".detail-tabs").offset().top - 180,
      });
      this.active = 2;
    },
    async getHotService() {
      //需求状态
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_staus.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzTaskStausJson[item.dictValue] = item.dictLabel;
          });
        });
      //需求标签
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_tags.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzTaskTagsJson[item.dictValue] = item.dictLabel;
          });
        });
      //推荐需求
      this.$sapi
        .get("/api/v1/getYzRecommendTaskMainList-1.json")
        .then((res) => {
          this.datalist = res.data.list;
        });
    },
    getOverDate2() {
      let endDate = this.overDate2Num;
      let theDate = new Date().getTime();
      let cdate = endDate - theDate;
      let overDate = { d: 0, h: 0, m: 0, s: 0 };
      if (cdate < 0) {
        this.isOver2 = true;
        return;
      }
      let d = cdate / 86400000;
      let dy = cdate % 86400000;
      if (d < 1) {
        overDate.d = 0;
      } else {
        overDate.d = ((cdate - dy) / 86400000).toFixed(0);
      }
      let h = dy / 3600000;
      let hy = dy % 3600000;
      if (h < 1) {
        overDate.h = 0;
      } else {
        overDate.h = ((dy - hy) / 3600000).toFixed(0);
      }
      let m = hy / 60000;
      let my = hy % 60000;
      if (m < 1) {
        overDate.m = 0;
      } else {
        overDate.m = ((hy - my) / 60000).toFixed(0);
      }
      let s = my / 1000;
      let sy = my % 1000;
      if (s < 1) {
        overDate.s = 0;
      } else {
        overDate.s = ((my - sy) / 1000).toFixed(0);
      }
      this.overDate2 = overDate;
      if (
        overDate.d == 0 &&
        overDate.h == 0 &&
        overDate.m == 0 &&
        overDate.s == 0
      ) {
        console.log("已过期");
      } else {
        setTimeout((_) => {
          this.getOverDate2();
        }, 1000);
      }
    },
    getOverDate() {
      let endDate = this.detail.overDate;
      let theDate = new Date().getTime();
      let cdate = endDate - theDate;
      let overDate = { d: 0, h: 0, m: 0, s: 0 };
      if (cdate < 0) {
        this.isOver = true;
        return;
      }
      let d = cdate / 86400000;
      let dy = cdate % 86400000;
      if (d < 1) {
        overDate.d = 0;
      } else {
        overDate.d = ((cdate - dy) / 86400000).toFixed(0);
      }
      let h = dy / 3600000;
      let hy = dy % 3600000;
      if (h < 1) {
        overDate.h = 0;
      } else {
        overDate.h = ((dy - hy) / 3600000).toFixed(0);
      }
      let m = hy / 60000;
      let my = hy % 60000;
      if (m < 1) {
        overDate.m = 0;
      } else {
        overDate.m = ((hy - my) / 60000).toFixed(0);
      }
      let s = my / 1000;
      let sy = my % 1000;
      if (s < 1) {
        overDate.s = 0;
      } else {
        overDate.s = ((my - sy) / 1000).toFixed(0);
      }
      this.overDate = overDate;
      if (
        overDate.d == 0 &&
        overDate.h == 0 &&
        overDate.m == 0 &&
        overDate.s == 0
      ) {
        console.log("已过期");
      } else {
        setTimeout((_) => {
          this.getOverDate();
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/supplier.scss";
.f-link {
  display: none;
}
</style>
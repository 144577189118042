<template>
  <div class="detail-tabs">
    <div class="content">
      <div class="common-title">
        <h3>需求详情 <span>DEMAND</span></h3>
      </div>
      <div class="detail-tabs-t">
        <template v-for="item in detailTabs">
          <div
            :class="{ item: true, on: item.id == detailTabsActive }"
            :key="item.id"
            v-if="
              (detail.yzViewShopInfo &&
                item.id == 2 &&
                (userInfo.userCode ==
                  detail.selectShoperYzTaskEvaluation.evaluationUserId ||
                  userInfo.userCode ==
                    detail.selectShoperYzTaskEvaluation.employerUid)) ||
              item.id != 2
            "
            @click="tabsChange(item)"
          >
            {{ item.title }}
          </div>
        </template>
      </div>
      <div class="detail-tabs-item" v-if="detailTabsActive == 1">
        <div class="item">
          <h3>浏览用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.viewCount || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>参与用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.assigmentCount || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>淘汰用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.taobai || 0 }}</font
            >人</span
          >
        </div>
        <div class="item">
          <h3>报价用户数</h3>
          <p>过去7日，所有用户</p>
          <span
            ><font>{{ detail.yzTaskDataStatistics.baojiaCount || 0 }}</font
            >人</span
          >
        </div>
      </div>
      <div class="terrace" v-if="detailTabsActive == 2">
        <div class="order-top">
          <div class="order-info">
            <div class="l">
              <p>跟踪订单：{{ detail.orderSn }}</p>
              <span v-if="yzTaskStaus" :class="statusClass[detail.yzTaskStaus]"
                >【{{ yzTaskStepsJson[detail.yzTaskStaus] }}】</span
              >
            </div>
            <div class="r" v-if="endDate">预计完成日期：{{ endDate }}</div>
          </div>
          <div class="order-step">
            <div
              :class="[
                'item',
                orderStep >= 1 ? 'on' : '',
                orderStep == 1 ? 'on active' : '',
              ]"
              @click="orderStepChange(1)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step1.png" alt="" />
              </div>
              <p>订单开始</p>
              <span>---------------->></span>
            </div>
            <div
              :class="[
                'item',
                orderStep >= 2 ? 'on' : '',
                orderStep == 2 ? 'active' : '',
              ]"
              @click="orderStepChange(2)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step2.png" alt="" />
              </div>
              <p>加工进程</p>
              <span>---------------->></span>
            </div>
            <div
              :class="[
                'item',
                orderStep >= 3 ? 'on' : '',
                orderStep == 3 ? 'active' : '',
              ]"
              @click="orderStepChange(3)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step3.png" alt="" />
              </div>
              <p>产品派送</p>
              <span>---------------->></span>
            </div>
            <div
              :class="['item', orderStep == 4 ? 'on active' : '']"
              @click="orderStepChange(4)"
            >
              <div class="icon">
                <img src="@/assets/images/public/step4.png" alt="" />
              </div>
              <p>质量反馈</p>
            </div>
          </div>
        </div>
        <div class="order-tabs">
          <div class="l">
            <template v-for="item in orderTabsList">
              <div
                :class="['item', orderTabsActive == item.id ? 'on' : '']"
                :key="item.id"
                @click="orderTabsChange(item)"
              >
                {{ item.title }}
              </div>
            </template>
          </div>
          <div class="r" v-if="orderStep == 1">
            <div class="history-list" v-if="orderTabsActive == 1">
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities1"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities2"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
              <div class="item">
                <el-timeline>
                  <el-timeline-item
                    v-for="(activity, index) in activities3"
                    :key="index"
                    :icon="activity.icon"
                    :color="activity.color"
                    :timestamp="activity.timestamp"
                  >
                    {{ activity.content }}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
            <div class="send" v-if="orderTabsActive == 2">
              <div class="not-sign" v-if="!isSign && !isEnd && hasShop">
                <h3>选择合同签署方式</h3>
                <p>
                  合同可以有效保障双方权益，减少争议纠纷<br />
                  我们为您提供以下两种合同签署方式，请选择适合您的合同签署方式
                </p>
                <div class="btn">
                  <el-button
                    :size="$store.state.isWap ? 'mini' : 'medium'"
                    type="primary"
                    @click="goSign(0)"
                    plain
                    >上传本地线下合同</el-button
                  >
                  <el-button
                    :size="$store.state.isWap ? 'mini' : 'medium'"
                    type="primary"
                    @click="goSign(1)"
                    >发起电子合同</el-button
                  >
                </div>
              </div>
              <div :class="['not-sign',isEnd?'on':'']" v-if="isEnd||!hasShop">
                <h3><i class="el-icon-circle-check" v-if="isEnd"></i> {{isEnd?'合同签署完成':'等待供应方发起合同 请注意查收站内信'}}</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button
                    type="primary"
                    :size="$store.state.isWap ? 'mini' : 'medium'"
                    @click="goSigns"
                    plain
                    >{{isEnd?'查看合同':'合同签署'}}</el-button
                  >
                </div>
              </div>
              <div class="not-sign" v-if="isSign && material == 0 && !isEnd && hasShop">
                <h3>合同签署</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button
                    type="primary"
                    :size="$store.state.isWap ? 'mini' : 'medium'"
                    @click="goSigns"
                    >编辑电子合同</el-button
                  >
                </div>
              </div>
              <div class="not-sign" v-if="isSign && material == 1 && !isEnd && hasShop">
                <h3>合同签署</h3>
                <p>合同可以有效保障双方权益，减少争议纠纷</p>
                <div class="btn">
                  <el-button
                    type="primary"
                    :size="$store.state.isWap ? 'mini' : 'medium'"
                    @click="goSigns"
                    >编辑本地线下合同</el-button
                  >
                </div>
              </div>
            </div>
            <div class="project" v-if="orderTabsActive == 3">
              <project
                @inits="inits"
                :yzTaskOrderPlanList="yzTaskOrderPlanList"
                :yzTaskMainId="detail.id"
                :contractInfo="contractInfo"
                :detail="detail"
                @goNext="goNext"
                :selectShoperYzTaskEvaluation="
                  detail.selectShoperYzTaskEvaluation
                "
              />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 4">
              <pay
                :yzTaskOrderPlanList="yzTaskOrderPlanList"
                @back="payBack"
                :yzTaskMainId="detail.id"
              />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 5">
              <supplement :yzTaskMainId="detail.id" />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 6">
              <invoice :yzTaskMainId="detail.id" />
            </div>
            <div class="pay-list" v-if="orderTabsActive == 7">
              <refund :yzTaskMainId="detail.id" />
            </div>
          </div>
          <div class="r" v-if="orderStep != 1">
            <step-two
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              @getNewData="getNewData"
              :yzTaskMainId="detail.id"
              v-if="orderStep == 2"
            />
            <step-three
              @getNewData="getNewData"
              @getContractInfo="getData"
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              :detail="detail"
              :overDate="overDate"
              :isOver="isOver"
              :yzTaskMainId="detail.id"
              v-if="orderStep == 4"
            />
            <step-four
              @getNewData="getNewData"
              :selectShoperYzTaskEvaluation="
                detail.selectShoperYzTaskEvaluation
              "
              :detail="detail"
              :yzTaskMainId="detail.id"
              v-if="orderStep == 3"
            />
          </div>
        </div>
      </div>
    </div>
    <contract-dialog
      ref="contract"
      :visible.sync="contractVisible"
      :detail="{ selectShoperYzTaskEvaluation: {}, ...detail }"
    />
  </div>
</template>
<script>
import supplement from "@/components/workbench/supplement";
import invoice from "@/components/workbench/invoice";
import refund from "@/components/workbench/refund";
import stepTwo from "@/components/workbench/step-two";
import stepThree from "@/components/workbench/step-three";
import stepFour from "@/components/workbench/step-four";
import pay from "@/components/workbench/pay";
import project from "@/components/workbench/project";
import upload from "@/components/servers/upload";
import contractDialog from "@/components/service/contract-dialog";
export default {
  components: {
    upload,
    contractDialog,
    supplement,
    invoice,
    refund,
    pay,
    project,
    stepTwo,
    stepThree,
    stepFour,
  },
  props: {
    detail: {
      type: Object,
      default: { selectShoperYzTaskEvaluation: {} },
    },
    active: {
      type: String | Number,
      default: 1,
    },
  },
  data() {
    return {
      isOver: false,
      overDate: { d: 0, h: 0, m: 0, s: 0 },
      yzTaskStaus: "",
      endDate: "",
      yzTaskStepsJson: {},
      statusClass: {
        1: "status1",
        2: "status1",
        3: "status2",
        4: "status2",
        5: "status3",
        6: "status3",
        7: "status4",
        8: "status1",
        9: "status1",
        10: "status1",
        11: "status1",
        12: "status1",
        13: "status1",
        14: "status2",
        15: "status2",
        16: "status2",
        17: "status1",
      },
      isEnd: false,
      material: 0,
      contractInfo: {},
      contractVisible: false,
      bizKey: "",
      activities1: [],
      activities2: [],
      activities3: [],
      orderTabsActive: 1,
      orderTabsList: [
        { title: "需求动态", id: 1 },
        { title: "发起合同", id: 2 },
        { title: "项目规划", id: 3 },
        // { title: "支付款项", id: 4 },
        { title: "资料补充", id: 5 },
        { title: "申请开票", id: 6 },
        // { title: "申请退款", id: 7 },
      ],
      orderStep: 1,
      detailTabs: [
        { id: 1, title: "参与服务商" },
        { id: 2, title: "工作台" },
        { id: 3, title: "交易说明" },
        { id: 4, title: "交易规则" },
      ],
      isSign: false,
      yzTaskOrderPlanList: [],
      yzTaskOrderPlanOperateStatus: 1,
      isOld: false,
      isOld2: false,
    };
  },
  computed: {
    hasShop() {
      return (
        this.detail.yzViewShopInfo &&
        this.userInfo.userCode ==
          this.detail.selectShoperYzTaskEvaluation.evaluationUserId
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    detailTabsActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
      },
    },
  },
  mounted() {},
  methods: {
    orderStepChange(i) {
      if (i == 2 && this.detail.yzTaskStaus >= 10) {
        this.orderStep = i;
        this.orderTabsActive = "";
      } else if (i == 3 && this.detail.yzTaskStaus >= 11) {
        this.orderStep = i;
        this.orderTabsActive = "";
      } else if (i == 4 && this.detail.yzTaskStaus >= 12) {
        this.orderStep = i;
        this.orderTabsActive = "";
      } else if (i == 1) {
        this.orderStep = i;
        this.orderTabsActive = 1;
      }
    },
    payBack() {
      this.orderTabsActive = 3;
    },
    goSigns() {
      this.contractVisible = true;
      this.$refs.contract.inits();
    },
    getOverDate() {
      let endDate =
        this.contractInfo.userReceiveGoodsTime + 1000 * 60 * 60 * 24 * 3;
      let theDate = new Date().getTime();
      let cdate = endDate - theDate;
      let overDate = { d: 0, h: 0, m: 0, s: 0 };
      if (cdate < 0) {
        this.isOver = true;
        return;
      }
      let d = cdate / 86400000;
      let dy = cdate % 86400000;
      if (d < 1) {
        overDate.d = 0;
      } else {
        overDate.d = ((cdate - dy) / 86400000).toFixed(0);
      }
      let h = dy / 3600000;
      let hy = dy % 3600000;
      if (h < 1) {
        overDate.h = 0;
      } else {
        overDate.h = ((dy - hy) / 3600000).toFixed(0);
      }
      let m = hy / 60000;
      let my = hy % 60000;
      if (m < 1) {
        overDate.m = 0;
      } else {
        overDate.m = ((hy - my) / 60000).toFixed(0);
      }
      let s = my / 1000;
      let sy = my % 1000;
      if (s < 1) {
        overDate.s = 0;
      } else {
        overDate.s = ((my - sy) / 1000).toFixed(0);
      }
      this.overDate = overDate;
      if (
        overDate.d == 0 &&
        overDate.h == 0 &&
        overDate.m == 0 &&
        overDate.s == 0
      ) {
        console.log("已过期");
      } else {
        setTimeout((_) => {
          this.getOverDate();
        }, 1000);
      }
    },
    getData() {
      if (!this.detail.selectShoperYzTaskEvaluation) return;
      this.$api
        .post(
          "/api/v1/getTaskContract",
          this.$common.getFormData({
            yzTaskEvaluationId: this.detail.selectShoperYzTaskEvaluation.id,
            yzTaskMainId: this.detail.id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.contractInfo = res.data;
            if (res.data.orderGoodsReceiveStatus == 1) {
              this.getOverDate();
            } else {
              this.isOver = true;
            }
            this.material = this.contractInfo.material;
            if (this.material == 0) {
              //线上，判断有没有serviceItems
              this.isSign = this.contractInfo.serviceItems;
            } else {
              //线下，判断listYTaskContractFileUpload
              this.isSign =
                this.contractInfo.listYTaskContractFileUpload &&
                this.contractInfo.listYTaskContractFileUpload.length;
            }
            if (
              this.contractInfo.taskUserStatus == 1 &&
              this.contractInfo.shoperUserStatus == 1
            ) {
              this.isEnd = true;
            } else {
              this.orderTabsActive = 2;
            }
            let preview = this.$route.query.preview;
            if (preview) {
              this.goSigns();
            }
          }
        });
    },
    goNext(i) {
      let next = false;
      if (typeof i === "object") {
        next = i.next;
        i = i.index;
      }
      this.orderTabsList = [
        { title: "需求动态", id: 1 },
        { title: "发起合同", id: 2 },
        { title: "项目规划", id: 3 },
        // { title: "支付款项", id: 4 },
        { title: "资料补充", id: 5 },
        { title: "申请开票", id: 6 },
        // { title: "申请退款", id: 7 },
      ];
      if (i == 1) {
        // this.orderTabsList.push({ title: "加工进程", id: 8 });
        if (next) {
          this.setStep(2, 2);
        }
      }
      if (i == 2) {
        // this.orderTabsList.push({ title: "加工进程", id: 8 });
        // this.orderTabsList.push({ title: "产品派送", id: 9 });
        if (next) {
          this.setStep(3, 3);
        }
      }
      if (i == 3) {
        // this.orderTabsList.push({ title: "加工进程", id: 8 });
        // this.orderTabsList.push({ title: "产品派送", id: 9 });
        // this.orderTabsList.push({ title: "质量检验", id: 10 });
        if (next) {
          this.setStep(4, 4);
        }
      }
    },
    //获取最新数据
    getNewData() {
      this.$api
        .get("/api/v1/getYzTaskMain", { params: { id: this.$route.query.id } })
        .then((res) => {
          this.$emit('update:detail',res.data)
          this.inits(true);
        });
    },
    //获取日志数据
    getLogData() {
      this.$api
        .get("/api/v1/getYzTaskLogsList?taskMainId=" + this.detail.id)
        .then((res) => {
          if (res.data && res.data.list) {
            let arr = res.data.list.map((item) => {
              return {
                content: item.title,
                timestamp: item.updateDate,
                color: "#2788ff",
              };
            });
            if (arr.length <= 20) {
              this.activities1 = arr.filter((item, index) => index < 10);
              this.activities2 = arr.filter(
                (item, index) => index >= 10 && index < 20
              );
            } else if (arr.length > 20 && arr.length <= 30) {
              this.activities1 = arr.filter((item, index) => index < 10);
              this.activities2 = arr.filter(
                (item, index) => index >= 10 && index < 20
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 20 && index < 30
              );
            } else if (arr.length > 30 && arr.length <= 45) {
              this.activities1 = arr.filter((item, index) => index < 15);
              this.activities2 = arr.filter(
                (item, index) => index >= 15 && index < 30
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 30 && index < 45
              );
            } else if (arr.length > 45 && arr.length <= 60) {
              this.activities1 = arr.filter((item, index) => index < 20);
              this.activities2 = arr.filter(
                (item, index) => index >= 20 && index < 40
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 40 && index < 60
              );
            } else if (arr.length > 60 && arr.length <= 90) {
              this.activities1 = arr.filter((item, index) => index < 30);
              this.activities2 = arr.filter(
                (item, index) => index >= 30 && index < 60
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 60 && index < 90
              );
            } else if (arr.length > 90 && arr.length <= 120) {
              this.activities1 = arr.filter((item, index) => index < 40);
              this.activities2 = arr.filter(
                (item, index) => index >= 40 && index < 80
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 80 && index < 120
              );
            } else if (arr.length > 120 && arr.length <= 150) {
              this.activities1 = arr.filter((item, index) => index < 50);
              this.activities2 = arr.filter(
                (item, index) => index >= 50 && index < 100
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 100 && index < 150
              );
            } else if (arr.length > 150 && arr.length <= 180) {
              this.activities1 = arr.filter((item, index) => index < 60);
              this.activities2 = arr.filter(
                (item, index) => index >= 60 && index < 120
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 120 && index < 180
              );
            } else if (arr.length > 180 && arr.length <= 210) {
              this.activities1 = arr.filter((item, index) => index < 70);
              this.activities2 = arr.filter(
                (item, index) => index >= 70 && index < 140
              );
              this.activities3 = arr.filter(
                (item, index) => index >= 140 && index < 210
              );
            }
          }
        });
    },
    inits(isNext) {
      let pay = this.$route.query.pay;
      let preview = this.$route.query.preview;
      let step = this.$route.query.step;
      let userCode = this.userInfo.userCode;
      let {
        selectShoperYzTaskEvaluation,
        yzViewShopInfo,
        yzTaskOrderPlanOperateStatus,
        yzTaskStaus,
      } = this.detail;
      //判断是否已登录
      if (userCode && selectShoperYzTaskEvaluation) {
        //需求状态--字典
        this.$sapi
          .get("/api/v1/getDictDataList-yz_task_staus.json")
          .then((res) => {
            res.forEach((item) => {
              this.yzTaskStepsJson[item.dictValue] = item.dictLabel;
            });
            this.yzTaskStaus = this.detail.yzTaskStaus;
          });
        //判断是否为中标工厂，或者发布需求的客户
        //服务商id
        let shopId = selectShoperYzTaskEvaluation.evaluationUserId;
        //需求放id
        let taskId = selectShoperYzTaskEvaluation.employerUid;
        //判断是否为当前需求参与人
        if (yzViewShopInfo && (userCode == shopId || userCode == taskId)) {
          this.detailTabsActive = 2;
          this.yzTaskOrderPlanOperateStatus = yzTaskOrderPlanOperateStatus;
          //获取日志数据
          this.getLogData();
          //获取合同数据
          this.getData();
          if (yzTaskStaus == 7 || yzTaskStaus == 8) {
            //订单未开始，签署合同中
            this.orderTabsActive = 2;
          } else if (yzTaskStaus == 9) {
            //订单开始---合同已签署
            this.orderTabsActive = 3;
          } else if (yzTaskStaus == 10) {
            //项目排程已确认 ---加工进程
            this.goNext({ index: 1, next: true });
            //预计结束时间 -- 项目排程确认之后
            this.$api
              .get(
                "/api/v1/getTaskOrderPlanEndTime?yzTaskMainId=" + this.detail.id
              )
              .then((res) => {
                if (res.code == 1) {
                  this.endDate = res.data.endTime.split(" ")[0];
                }
              });
          } else if (yzTaskStaus == 11||yzTaskStaus == 17) {
            //加工进程已确认， 产品派送
            this.goNext({ index: 2, next: true });
          } else if (yzTaskStaus == 12) {
            //产品收货完成 评价
            this.goNext({ index: 3, next: true });
          }
          if (pay && pay == 1) {
            this.orderTabsActive = 4;
          }
          if (step && !this.isOld) {
            this.isOld = true;
            this.setStep(1);
          }
        }
      } else if (!userCode) {
        //未登录，跳转登录
        if (preview || step || (pay && pay == 1)) {
          location.href = `${
            window.config.api
          }/a/login?__url=${encodeURIComponent(location.href)}`;
        }
      }
    },
    setStep(i, next) {
      let step = this.$route.query.step || next;
      this.orderTabsActive = "";
      this.detailTabsActive = 2;
      if (step == 1 && i >= 1) {
        this.orderTabsActive = 1;
        this.orderStep = 1;
      }
      if (step == 2 && i >= 2) {
        this.orderStep = 2;
      }
      if (step == 3 && i >= 3) {
        this.orderStep = 3;
      }
      if (step == 4 && i >= 4) {
        this.orderStep = 4;
      }
    },
    goSign(i) {
      if (i == 1) {
        //线上
        this.$confirm(`您确定要选择线上合同签署方式吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$api
            .post(
              "/api/v1/settingonlinecontact",
              this.$common.getFormData({
                id: this.detail.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  if ((res.code = 1)) {
                    this.contractVisible = true;
                    this.$refs.contract.inits();
                  }
                },
              });
            });
        });
      } else {
        this.$confirm(`您确定要选择线下合同签署方式吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$api
            .post(
              "/api/v1/settingofflinecontact",
              this.$common.getFormData({
                id: this.detail.id,
              })
            )
            .then((res) => {
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  if ((res.code = 1)) {
                    this.contractVisible = true;
                    this.$refs.contract.inits();
                  }
                },
              });
            });
        });
      }
    },
    upSuccess(files) {},
    tabsChange(item) {
      this.detailTabsActive = item.id;
    },
    orderTabsChange(item) {
      this.orderTabsActive = item.id;
      if (item.id == 8) {
        this.orderStep = 2;
      } else if (item.id == 9) {
        this.orderStep = 3;
      } else if (item.id == 10) {
        this.orderStep = 4;
      } else {
        this.orderStep = 1;
      }
    },
  },
};
</script>
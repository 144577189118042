<template>
  <div class="project-main">
    <el-tabs v-model="activeName" @tab-click="tabChange">
      <el-tab-pane label="项目排程" name="2"></el-tab-pane>
      <el-tab-pane label="工艺确认" name="1"></el-tab-pane>
    </el-tabs>
    <div class="table-list" v-if="activeName == 1">
      <el-table
        class="craft-table"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        size="small"
        style="width: 100%"
        height="350px"
        border
      >
        <el-table-column label="工艺文件名称" prop="fileName" width="200">
        </el-table-column>
        <el-table-column
          label="文件大小"
          prop="fileEntity.fileSizeFormat"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="上传时间"
          width="150"
          prop="createDate"
          align="center"
        >
        </el-table-column>
        <el-table-column label="下载量" prop="createByName" align="center">
          <template slot-scope="scope">
            <div>
              已下载
              <span style="color: #232ae6">
                {{ (scope.row.extend && scope.row.extend.extendI4) || 0 }}
              </span>
              次
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="需求方状态"
          width="120"
          align="center"
          v-if="hasShop"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.extend && scope.row.extend.extendS2"
              :class="{
                'affirm-span': true,
                on: scope.row.extend && scope.row.extend.extendS2 == 1,
              }"
              >{{
                scope.row.extend && scope.row.extend.extendS2 == 1
                  ? "已确认"
                  : "已否决"
              }}</span
            >
            <span v-else>待确认</span>
          </template>
        </el-table-column>
        <el-table-column
          label="供应商状态"
          width="100"
          align="center"
          v-if="!hasShop"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.extend && scope.row.extend.extendS5"
              :class="{
                'affirm-span': true,
                on: scope.row.extend && scope.row.extend.extendS5 == 1,
              }"
              >{{
                scope.row.extend && scope.row.extend.extendS5 == 1
                  ? "已确认"
                  : "已否决"
              }}</span
            >
            <span v-else>待确认</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="goDown(scope.row)"
              size="mini"
              icon="el-icon-download"
              >下载</el-button
            >
            <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS4) ||
                  !scope.row.extend) &&
                hasShop
              "
              type="success"
              size="mini"
              icon="el-icon-circle-check"
              @click="goYes(scope.row)"
              :loading="scope.row.yesLoad"
              >确认</el-button
            >
            <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS4) ||
                  !scope.row.extend) &&
                hasShop
              "
              type="danger"
              size="mini"
              icon="el-icon-circle-close"
              @click="goNot(scope.row)"
              :loading="scope.row.notLoad"
              >否决</el-button
            >

            <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS3) ||
                  !scope.row.extend) &&
                !hasShop
              "
              type="success"
              size="mini"
              icon="el-icon-circle-check"
              @click="goYes(scope.row, true)"
              :loading="scope.row.yesLoad"
              >确认</el-button
            >
            <el-button
              v-if="
                scope.row.createBy != userInfo.userCode &&
                ((scope.row.extend && !scope.row.extend.extendS3) ||
                  !scope.row.extend) &&
                !hasShop
              "
              type="danger"
              size="mini"
              icon="el-icon-circle-close"
              @click="goNot(scope.row, true)"
              :loading="scope.row.notLoad"
              >否决</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="f-btn">
        <upload
          ref="upload"
          mode="craft"
          btnText="上传工艺文件"
          format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
          uploadType="all"
          @checkSuccess="checkSuccess"
          @success="upSuccess"
        />
      </div>
    </div>
    <div class="table-list" v-if="activeName == 2">
      <el-form
        :model="projectForm"
        ref="projectForm"
        size="small"
        class="project-form"
        label-suffix="："
      >
        <el-form-item label="工期范围" v-if="commonStatus.needTime">
          <span
            >{{ commonStatus.beginTime.split(" ")[0] }}至
            {{ commonStatus.endTime.split(" ")[0] }}</span
          >
          <span class="days">工期：{{ commonStatus.needTime }}天</span>
        </el-form-item>
        <el-form-item label="阶段状态">
          <span
            v-if="shoperUserSureExecuteStatus == 1"
            :class="['status', status ? 'on' : '']"
            >{{ status ? "需求方已确认" : "需求方待确认" }}</span
          >
          <span
            v-if="shoperUserSureExecuteStatus == 0"
            :class="['status', 'err']"
            >需求方否决</span
          >
          <span
            v-if="shoperUserSureExecuteStatus == undefined"
            :class="['status']"
            >服务商待上传与确认</span
          >
        </el-form-item>
        <div class="server-chat" @click="goChat"><img src="@/assets/images/public/servers2.png" alt=""></div>
      </el-form>
      <el-form
        :model="forms"
        ref="tablelists"
        size="small"
        :class="['tablelists-form', status == 1 ? 'on' : '']"
      >
        <el-table
          size="small"
          :data="forms.yzTaskOrderPlanList"
          style="width: 100%"
          border
          ref="multipleTable"
          empty-text=" "
          row-key="id"
          v-loading="tableLoad"
          @selection-change="handleSelectionChange"
          fit
          :class="hasSort ? 'has-sort' : ''"
        >
          <!-- <el-table-column align="center" label="拖拽" width="80">
            <template slot-scope="{}">
              <i class="iconfont icon-tuozhuai"></i>
            </template>
          </el-table-column> -->
          <el-table-column prop="sort" align="center" label="编号" width="60">
            <template slot-scope="scope">
              <el-form-item
                :rules="{
                  required: !scope.row.preview,
                  message: '请输入',
                  trigger: 'blur',
                }"
                :prop="`yzTaskOrderPlanList.${scope.$index}.sort`"
              >
                <el-input
                  v-if="!scope.row.preview"
                  v-model="scope.row.sort"
                ></el-input>
                <p v-if="scope.row.preview">{{ scope.row.sort }}</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="step"
            :width="hasShop && !status ? 230 : 270"
            align="center"
            label="项目阶段"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.step`"
                :rules="{
                  required: !scope.row.preview,
                  message: '请选择项目阶段',
                  trigger: 'change',
                }"
              >
                <el-row>
                  <el-col :span="scope.row.step == 10 ? 12 : 24">
                    <el-select
                      v-model="scope.row.stepObj"
                      placeholder="请选择"
                      v-if="!scope.row.preview"
                      value-key="dictValue"
                      @change="stepChange"
                    >
                      <el-option
                        v-for="item in stepList"
                        :key="item.dictValue"
                        :label="item.dictLabel"
                        :value="{ ...item, pid: scope.row.id }"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col
                    :span="11"
                    :offset="1"
                    v-if="scope.row.step == 10 && !scope.row.preview"
                  >
                    <el-input
                      placeholder="请输入项目内容"
                      v-model="scope.row.stepElseValue"
                    ></el-input>
                  </el-col>
                </el-row>
                <p v-if="scope.row.preview&&scope.row.step!=10">
                  {{ stepJson[scope.row.step] || scope.row.step }}
                </p>
                <p v-if="scope.row.preview&&scope.row.step==10">
                  {{ scope.row.stepElseValue }}
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="workContent"
            :width="hasShop && !status ? 110 : 150"
            align="center"
            label="项目内容"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.workContent`"
                :rules="{
                  required: !scope.row.preview,
                  message: '请输入项目内容',
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="scope.row.workContent"
                  placeholder="请选择"
                  v-if="!scope.row.preview && scope.row.step == 5"
                >
                  <el-option
                    v-for="item in contentList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="scope.row.step != 5 && !scope.row.preview"
                  placeholder="请输入项目内容"
                  v-model="scope.row.workContent"
                ></el-input>
                <p v-if="scope.row.preview">
                  {{
                    contentJson[scope.row.workContent] || scope.row.workContent
                  }}
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="commitContent"
            :width="hasShop && !status ? 110 : 130"
            align="center"
            label="付款比例"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.commitContent`"
                :rules="{
                  required: !scope.row.preview,
                  message: '请选择付款比例',
                  trigger: 'blur',
                }"
              >
                <el-select
                  v-model="scope.row.commitContentObj"
                  placeholder="请选择"
                  :disabled="scope.row.step != 5"
                  v-if="!scope.row.preview"
                  @change="commitChange"
                  value-key="dictValue"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="{ ...item, pid: scope.row.id }"
                  >
                  </el-option>
                </el-select>
                <p v-if="scope.row.preview">
                  {{
                    statusJson[scope.row.commitContent] ||
                    scope.row.commitContent
                  }}
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="commitMoney"
            width="120"
            align="center"
            label="付款金额"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.commitMoney`"
                :rules="{
                  required:
                    !scope.row.preview &&
                    theId == scope.row.id &&
                    scope.row.step == 5,
                  message: '请输入付款金额',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!scope.row.preview"
                  type="number"
                  :disabled="scope.row.step != 5"
                  placeholder="请输入金额"
                  v-model="scope.row.commitMoney"
                  readonly
                >
                  <span slot="suffix" class="unit">元</span>
                </el-input>
                <p v-if="scope.row.preview">{{ scope.row.commitMoney }}元</p>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="needArea"
            align="center"
            :width="hasShop && !status ? 220 : 300"
            label="工期范围"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.needArea`"
                :rules="{
                  required: !scope.row.preview,
                  message: '请选择工期范围',
                  trigger: 'blur',
                }"
              >
                <div class="picker">
                  <el-date-picker
                    v-if="!scope.row.preview"
                    v-model="scope.row.needArea"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    @change="needTimeChange"
                    format="yy-MM-dd"
                  >
                  </el-date-picker>
                </div>
                <p v-if="scope.row.preview && scope.row.beginTime">
                  {{ scope.row.beginTime.split(" ")[0] }}至{{
                    scope.row.endTime.split(" ")[0]
                  }}
                </p>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="needTime"
            width="60"
            align="center"
            label="工时"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="`yzTaskOrderPlanList.${scope.$index}.needTime`"
              >
                <p>{{ scope.row.needTime || 0 }}天</p>
              </el-form-item>
            </template>
          </el-table-column> -->
          <el-table-column
            v-if="hasShop && !status"
            prop="sort"
            align="center"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <el-form-item>
                <el-button
                  v-if="
                    scope.row.createBy == userInfo.userCode ||
                    scope.row.isNewRecord
                  "
                  size="mini"
                  type="danger"
                  @click="del(scope.row)"
                >
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div class="price">
        <div class="item">
          含税价格：<span>{{ contractInfo.price }}元</span>
        </div>
        <div class="item">
          总价格：<span>{{ contractInfo.saxPrice }}元</span>
        </div>
        <div class="item">
          排程价格：<span>{{ totalPrice }}元</span>
        </div>
      </div>
      <div
        class="desc"
        v-if="
          !hasShop &&
          !status &&
          shoperUserSureExecuteStatus == 1 &&
          forms.yzTaskOrderPlanList.length >= 1
        "
      >
        <el-input
          type="textarea"
          rows="3"
          v-model="description"
          placeholder="请添加备注"
        ></el-input>
      </div>
      <div class="f-btn">
        <el-button
          v-if="
            !hasShop &&
            !status &&
            forms.yzTaskOrderPlanList.length >= 1 &&
            shoperUserSureExecuteStatus == 1
          "
          size="small"
          type="success"
          icon="el-icon-circle-check"
          :loading="yesLoad"
          @click="goYes2()"
        >
          同意
        </el-button>
        <el-button
          v-if="
            !hasShop &&
            !status &&
            forms.yzTaskOrderPlanList.length >= 1 &&
            shoperUserSureExecuteStatus == 1
          "
          :loading="notLoad"
          size="small"
          type="danger"
          icon="el-icon-circle-close"
          @click="goNot2()"
        >
          否决
        </el-button>
        <el-button
          v-if="
            hasShop &&
            !status &&
            preview &&
            forms.yzTaskOrderPlanList.length >= 1
          "
          size="small"
          type="primary"
          icon="el-icon-edit"
          @click="edit()"
        >
          编辑
        </el-button>
        <el-button
          v-if="
            hasShop &&
            !status &&
            !preview &&
            forms.yzTaskOrderPlanList.length >= 1
          "
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="save()"
        >
          保存
        </el-button>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          v-if="hasShop && !status"
          @click="addSchedule"
          >添加项目排程</el-button
        >
        <!-- <el-button
          type="success"
          size="small"
          icon="el-icon-circle-check"
          v-if="hasShop && status"
          @click="goNext"
          >确认执行项目规划</el-button
        > -->
        <el-button
          type="success"
          size="small"
          icon="el-icon-message"
          v-if="hasShop && !status && forms.yzTaskOrderPlanList.length >= 1"
          @click="goSend"
          >提醒客户确认项目规划</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
import Sortable from "sortablejs";
import Snowflake from "@/common/snowflake";
import fastUpVue from "../servers/fastUp.vue";
export default {
  components: { upload },
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    detail: {
      default() {
        return {};
      },
      type: Object,
    },
    contractInfo: {
      default() {
        return {};
      },
      type: Object,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    isNotNewsAdd() {
      let is = false;
      this.forms.yzTaskOrderPlanList.forEach((item) => {
        if (!item.isNewRecord) {
          is = true;
        }
      });
      return is;
    },
    totalPrice() {
      let totalPrice = 0;
      this.forms.yzTaskOrderPlanList.forEach((item) => {
        totalPrice = this.$common.floatAdd(
          totalPrice,
          parseInt(this.statusJson[item.commitContent])
        );
      });
      return totalPrice
        ? this.$common.floatMul(
            this.$common.floatDiv(totalPrice, 100),
            this.contractInfo.saxPrice
          )
        : 0;
    },
    preview: {
      get() {
        let preview = true;
        this.forms.yzTaskOrderPlanList.forEach((item) => {
          if (!item.preview) {
            preview = false;
          }
        });
        return preview;
      },
    },
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.evaluationUserId ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  data() {
    return {
      shoperUserSureExecuteStatus: undefined,
      description: "",
      hasSort: false,
      tableLoad: false,
      days: 0,
      activeName: "2",
      projectForm: { duration: "", filesName: "" },
      tableData: [],
      forms: { yzTaskOrderPlanList: [] },
      stepList: [],
      stepJson: {},
      statusList: [],
      statusJson: {},
      theId: "",
      operateStatusList: [
        { dictLabel: "已确认", dictValue: "1" },
        { dictLabel: "未确认", dictValue: "0" },
      ],
      operateStatusJson: { 1: "已确认", 0: "未确认" },
      sortable: null,
      oldList: [],
      newList: [],
      sortKey: new Date().getTime(), // 为了方便拖动失败，回到拖动之前
      selectArray: [],
      commonStatus: {},
      yesLoad: false,
      notLoad: false,
      contentList: [],
      contentJson: {},
      status: 0,
      loadUps:{}
    };
  },
  mounted() {
    //支付进度
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_type.json")
      .then((res) => {
        this.stepList = res;
        res.forEach((item) => {
          this.stepJson[item.dictValue] = item.dictLabel;
        });
        this.inits();
      });
    //阶段状态
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_pay.json")
      .then((res) => {
        this.statusList = res;
        res.forEach((item) => {
          this.statusJson[item.dictValue] = item.dictLabel;
        });
      });
    //项目内容
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_content.json")
      .then((res) => {
        this.contentList = res;
        res.forEach((item) => {
          this.contentJson[item.dictValue] = item.dictLabel;
        });
      });
  },
  methods: {
    commitChange(val) {
      this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
        (item) => {
          if (item.id == val.pid) {
            item.commitContent = val.dictValue;
            item.commitMoney = this.$common.floatMul(
              this.$common.floatDiv(
                parseInt(this.statusJson[val.dictValue]),
                100
              ),
              this.contractInfo.saxPrice
            );
          }
          return item;
        }
      );
    },
    stepChange(val) {
      if (val.dictValue == 10) {
        this.$prompt("请输入自定义阶段名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
          inputErrorMessage: "请输入自定义阶段名称",
        })
          .then(({ value }) => {
            this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
              (item) => {
                if (item.id == val.pid) {
                  item.commitContent = "6";
                  item.commitMoney = 0;
                  item.workContent = "";
                  item.step = 10;
                  item.stepElseValue = value;
                }
                return item;
              }
            );
          })
          .catch(() => {
            this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
              (item) => {
                if (item.id == val.pid) {
                  item.commitContent = "";
                  item.commitContentObj = {};
                  item.commitMoney = 0;
                  item.workContent = "";
                  item.step = "";
                  item.stepElseValue = "";
                  item.stepObj = "";
                }
                return item;
              }
            );
          });
      } else {
        this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
          (item) => {
            if (item.id == val.pid) {
              item.commitContentObj = {};
              item.commitMoney = 0;
              if (val.dictValue != 5) {
                item.commitContentObj = this.statusList.find(
                  (item) => item.dictValue == 6
                );
                item.commitContent = "6";
                item.commitMoney = 0;
              }
              item.workContent = "";
              item.step = val.dictValue;
            }
            return item;
          }
        );
      }
    },
    goSend() {
      this.$api
        .post(
          "/api/v1/sendAddNewYzTaskOrderPlanMessage",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
          if (this.shoperUserSureExecuteStatus != 1) {
            this.inits();
          }
        });
    },
    goPlan(files, i = 0) {
      this.$api
        .post(
          "/api/v1/maskSureRunTaskOrderPlan",
          this.$common.getFormData({
            yzTaskOrderPlanId: files[i].id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.$message({
                message: "确认执行项目规划成功！",
                type: "success",
                duration: 1500,
              });
              this.activeName = "2";
            } else {
              i++;
              //未结束，继续执行
              this.goPlan(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
        });
    },
    /**
     * 拖拽排序
     */
    setSort() {
      if (
        !this.hasShop ||
        this.status ||
        this.forms.yzTaskOrderPlanList.length < 2
      )
        return;
      this.hasSort = true;
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      this.sortable = Sortable.create(tbody, {
        ghostClass: "sortable-ghost", // C
        onEnd: ({ newIndex, oldIndex }) => {
          if (!this.hasSort || this.forms.yzTaskOrderPlanList.length < 2)
            return;
          let oldObj = this.forms.yzTaskOrderPlanList[oldIndex];
          let newObj = this.forms.yzTaskOrderPlanList[newIndex];
          this.upSort(oldObj, newObj);
        },
      });
    },
    async upSort(oldObj, newObj) {
      this.tableLoad = true;
      await this.$api
        .post(
          "/api/v1/saveTaskOrderPlan",
          this.$common.getFormData({
            ...oldObj,
            sort: newObj.sort,
          })
        )
        .then((res) => console.log(res));
      await this.$api
        .post(
          "/api/v1/saveTaskOrderPlan",
          this.$common.getFormData({
            ...newObj,
            sort: oldObj.sort,
          })
        )
        .then((res) => console.log(res));
      this.inits();
    },
    tabChange() {
      this.inits();
    },
    save() {
      this.$nextTick(() => {
        this.$refs.tablelists.validate((valid) => {
          if (valid) {
            let list = this.forms.yzTaskOrderPlanList.map((item) => {
              let {
                sort,
                step,
                workContent,
                commitContent,
                commitMoney,
                needTime,
                operateStatus,
                isNewRecord,
                beginTime,
                endTime,
                id,
                stepElseValue,
              } = item;
              return {
                id,
                taskOrderId: this.yzTaskMainId,
                step: step == 10 ? stepElseValue : step,
                sort,
                workContent,
                commitContent,
                commitMoney,
                needTime,
                operateStatus,
                isNewRecord,
                beginTime,
                endTime,
                yzTaskMainId: this.yzTaskMainId,
              };
            });
            this.$api
              .post(
                "/api/v1/saveTaskOrderPlanList",
                this.$common.getFormData({
                  yzTaskMainId: this.yzTaskMainId,
                  yzTaskOrderPlan: { listYzTaskOrderPlan: list },
                })
              )
              .then((res) => {
                if (res.code == 1) {
                  this.inits();
                }
                this.$message({
                  message: res.message,
                  type: res.code == 1 ? "success" : "error",
                  duration: 1500,
                });
              });
          }
        });
      });
    },
    durationChange(area) {
      if (area.length > 1) {
        let s = this.$common.getFormat({
          date: area[0],
          format: "YYYY/MM/DD 00:00:00",
        });
        let e = this.$common.getFormat({
          date: area[1],
          format: "YYYY/MM/DD 00:00:00",
        });
        this.days = (new Date(e).getTime() - new Date(s).getTime()) / 86400000;
      }
    },
    needTimeChange(area) {
      this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
        (item) => {
          if (item.needArea.length > 1) {
            let a =
              typeof item.needArea[0] != "object"
                ? item.needArea[0].replace(/-/g, "/")
                : item.needArea[0];
            let b =
              typeof item.needArea[1] != "object"
                ? item.needArea[1].replace(/-/g, "/")
                : item.needArea[1];
            item.beginTime = this.$common.getFormat({
              date: new Date(a),
              format: "YYYY-MM-DD 00:00:00",
            });
            item.endTime = this.$common.getFormat({
              date: new Date(b),
              format: "YYYY-MM-DD 00:00:00",
            });
            let s = this.$common.getFormat({
              date: new Date(a),
              format: "YYYY/MM/DD 00:00:00",
            });
            let e = this.$common.getFormat({
              date: new Date(b),
              format: "YYYY/MM/DD 00:00:00",
            });
            item.needTime =
              (new Date(e).getTime() - new Date(s).getTime()) / 86400000;
          }
          return item;
        }
      );
    },
    edit() {
      this.hasSort = false;
      try {
        this.sortable.destroy();
      } catch (error) {
        console.log(error);
      }
      this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.map(
        (item) => {
          item.preview = !item.preview;
          return item;
        }
      );
    },
    del(row) {
      let { id } = row;
      if (row.isNewRecord) {
        this.forms.yzTaskOrderPlanList = this.forms.yzTaskOrderPlanList.filter(
          (item) => {
            return item.id != id;
          }
        );
        let is = true;
        this.forms.yzTaskOrderPlanList.forEach((item) => {
          if (item.isNewRecord) {
            is = false;
          }
        });
        if (is && this.hasShop) {
          this.hasSort = true;
        }
      } else {
        this.$api
          .post(
            "/api/v1/deleteTaskOrderPlan",
            this.$common.getFormData({
              id,
              taskOrderId: this.yzTaskMainId,
              yzTaskMainId: this.yzTaskMainId,
            })
          )
          .then((res) => {
            if (res.code == 1) {
              this.inits();
            }
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
          });
      }
    },
    addSchedule() {
      this.hasSort = false;
      try {
        this.sortable.destroy();
      } catch (error) {
        console.log(error);
      }
      this.forms.yzTaskOrderPlanList.push({
        sort: "",
        id: new Snowflake(
          this.forms.yzTaskOrderPlanList.length + 1,
          1,
          0
        ).nextId(),
        taskOrderId: "",
        step: "",
        stepObj: {},
        stepElseValue: "",
        workContent: "",
        commitContent: "",
        commitContentObj: {},
        commitMoney: "",
        needTime: "",
        operateStatus: "",
        isNewRecord: true,
        needArea: [],
        beginTime: "",
        endTime: "",
        preview: false,
      });
    },
    goYes(row, is) {
      this.tableData = this.tableData.map((item) => {
        if (item.id == row.id) {
          item.yesLoad = true;
        }
        return item;
      });
      this.$api
        .post(
          !is ? "/api/v1/shoperUserSureMake" : "/api/v1/taskUserSureMake",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            fileUploadId: row.id,
          })
        )
        .then((res) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.yesLoad = false;
            }
            return item;
          });
          if (res.code == 1) {
            this.inits();
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.yesLoad = false;
            }
            return item;
          });
        });
    },
    goNot(row, is) {
      this.tableData = this.tableData.map((item) => {
        if (item.id == row.id) {
          item.notLoad = true;
        }
        return item;
      });
      this.$api
        .post(
          is ? "/api/v1/taskUserRefuseMake" : "/api/v1/shoperUserRefuseMake",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            fileUploadId: row.id,
          })
        )
        .then((res) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.notLoad = false;
            }
            return item;
          });
          if (res.code == 1) {
            this.inits();
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.tableData = this.tableData.map((item) => {
            if (item.id == row.id) {
              item.notLoad = false;
            }
            return item;
          });
        });
    },
    goYes2() {
      this.yesLoad = true;
      this.$api
        .post(
          "/api/v1/taskUserMakeSureRunTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            description: this.description,
          })
        )
        .then((res) => {
          this.yesLoad = false;
          if (res.code == 1) {
            this.inits(true);
            this.description = "";
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.yesLoad = false;
        });
    },
    goNot2() {
      this.notLoad = true;
      this.$api
        .post(
          "/api/v1/taskUserUnMakeSureRunTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            description: this.description,
          })
        )
        .then((res) => {
          this.notLoad = false;
          if (res.code == 1) {
            this.inits();
            this.description = "";
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.notLoad = false;
        });
    },
    goDown(row) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `${window.config.api}/a/file/download/${row.id}`;
    },
    getData() {
      this.$api
        .post(
          "/api/v1/getTaskOrderPlanMakeArtListFile",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.map((item) => {
              item.yesLoad = false;
              item.notLoad = false;
              item.preview = true;
              return item;
            });
          }
        });
    },
    getYzTaskOrderPlanList(next) {
      this.tableLoad = true;
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
            beginTime: "",
            endTime: "",
          })
        )
        .then((res) => {
          this.tableLoad = false;
          console.log("项目规划", res.data);
          this.forms.yzTaskOrderPlanList = res.data.map((item) => {
            item.preview = true;
            let obj = this.stepList.find(
              (items) => items.dictValue == item.step
            );
            if (obj) {
              item.stepObj = obj;
            } else {
              item.stepObj = this.stepList.find(
                (items) => items.dictValue == 10
              );
              item.stepElseValue = item.step;
              item.step = 10;
            }
            let obj2 = this.statusList.find(
              (items) => items.dictValue == item.commitContent
            );
            if (obj2) {
              item.commitContentObj = obj2;
            }
            if (item.beginTime && item.endTime) {
              item.needArea = [item.beginTime, item.endTime];
            }
            if (item.shoperUserSureExecuteStatus != undefined) {
              this.shoperUserSureExecuteStatus =
                item.shoperUserSureExecuteStatus;
            }
            return item;
          });
          this.oldList = this.forms.yzTaskOrderPlanList.map((v) => v.id);
          this.newList = this.oldList.slice();
          this.$nextTick(() => {
            this.setSort();
          });
        });
    },
    inits(next) {
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlanStatistics",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.commonStatus = res.data;
          }
        });
      this.$api
        .post(
          "/api/v1/getTaskOrderPlanCurrentTotalStatus",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.status = res.data;
            if (this.status) {
              this.$emit("goNext", next ? { index: 1, next: true } : 1);
            }
          }
        });
      this.activeName == 1 ? this.getData() : this.getYzTaskOrderPlanList(next);
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          "/api/v1/uploadTaskOrderPlanMakeArtFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.$message({
                message: "上传工艺成功！",
                type: "success",
                duration: 1500,
              });
              this.loadUps.close ? this.loadUps.close() : null;
              this.inits();
            } else {
              i++;
              //未结束，继续关联
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.loadUps.close ? this.loadUps.close() : null;
          }
        })
        .catch((err) => {
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
          this.loadUps.close ? this.loadUps.close() : null;
        });
    },
    checkSuccess(files) {
      if (files) {
        this.$refs.upload.submit();
      }
    },
    upSuccess(files) {
      if (files) {
        this.loadUps = this.$message({
          message: "正在处理文件，请不要关闭页面",
          iconClass: "el-icon-loading",
          type: "success",
          duration: 0,
        });
        this.goAbout(files);
      }
    },
    handleSelectionChange(selection) {
      this.selectArray = selection;
      console.log(selection);
    },
    goChat() {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        `https://www.huixianggongye.com/f/contactKefu?toUser=${this.detail.customerUser.userCode}`;
    },
  },
};
</script>
<style lang="scss">
.picker {
  .el-range-editor {
    width: 100%;
  }
  .el-range-separator {
    width: 10px;
  }
  .el-range-editor--small .el-range-input {
    width: 100%;
  }
}
.project {
  .el-form-item__content {
    font-size: 14px;
  }
  .tablelists-form {
    &.on {
      .el-form-item {
        margin: 5px 0;
      }
    }
    .el-table th > .cell {
      font-size: 14px;
    }
    .has-sort tr {
      cursor: all-scroll;
    }
    .el-table {
      .el-form-item {
        .el-input__inner {
          padding-left: 5px;
          padding-right: 5px;
        }
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
    .el-form-item {
      margin: 15px 0;
      .el-form-item__error {
        padding-top: 1px;
      }
    }

    .el-table--enable-row-transition .el-table__body td {
      padding: 0;
    }
  }
}
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: fade-out($color: $c2, $amount: 0.9) !important;
}
</style>
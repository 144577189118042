<template>
  <div class="chat-user" v-if="close&&detail.customerUser">
    <el-image
      :src="
        detail.customerUser.avatar ||
        require('@/assets/images/public/servers.png')
      "
      fit="cover"
     lazy></el-image>
    <h3>项目顾问-{{ detail.customerUser.userName }}</h3>
    <div class="tags">
      <span class="common-label">商务沟通</span>
      <span class="common-label">质量管理</span>
      <span class="common-label">项目管理</span>
    </div>
    <div class="desc">
      个人宣言：{{ detail.customerUser.sign }}
    </div>
    <div class="icon" @click="goChat">
      <i class="iconfont icon-liaotian"></i>
      <p>{{detail.customerUser.extend.extendI4==1?"在线":"离线"}}沟通</p>
    </div>
    <span class="el-icon-close" @click="close = false"></span>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object|Array,
      default: { customerUser: {} },
    },
  },
  data() {
    return {
      close: true,
    };
  },
  methods: {
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        `https://www.huixianggongye.com/f/contactKefu?toUser=${this.detail.customerUser.userCode}`;
    },
  },
};
</script>
<style lang="scss">
</style>
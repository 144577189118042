<template>
  <el-dialog
    class="contract-dialog"
    :visible.sync="visibles"
    :width="$store.state.isWap ? '90vw' : '1200px'"
    :modal-append-to-body="true"
    :append-to-body="true"
    :lock-scroll="true"
    :before-close="handleClose"
  >
    <div class="loading" v-if="material == 2">
      <span class="el-icon-loading"></span>合同加载中...
    </div>
    <contract
      v-show="material == 0"
      :detail="detail"
      @close="close"
      @inits="inits"
      :contractInfo="contractInfo"
      ref="contract"
    />
    <offline
      v-show="material == 1"
      :detail="detail"
      @close="close"
      @inits="inits"
      :contractInfo="contractInfo"
      ref="offline"
    />
  </el-dialog>
</template>
<script>
import contract from "./contract";
import offline from "./offline";
export default {
  components: { contract, offline },
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {
      contractInfo: {},
      material: 2,
    };
  },
  computed: {
    visibles: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    close() {
      this.visibles = false;
    },
    inits() {
      this.getData();
    },
    getData() {
      this.$api
        .post(
          "/api/v1/getTaskContract",
          this.$common.getFormData({
            yzTaskEvaluationId: this.detail.selectShoperYzTaskEvaluation.id,
            yzTaskMainId: this.detail.id,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.contractInfo = res.data;
            this.material = this.contractInfo.material;
            this.$nextTick(() => {
              if (this.material == 0) {
                this.$refs.contract && this.$refs.contract.inits();
              } else {
                this.$refs.offline && this.$refs.offline.inits();
              }
            });
            // console.log(this.contractInfo);
          }
        });
    },
    handleClose() {
      this.visibles = false;
      if (this.material == 0) {
        this.$refs.contract && this.$refs.contract.inits();
      } else {
        this.$refs.offline && this.$refs.offline.inits();
      }
    },
  },
};
</script>
<style lang="scss">
.contract-dialog {
  .el-dialog {
    margin: 4vw auto 2vw !important;
  }
  .el-dialog__body {
    padding-top: 0;
  }
}

@media (max-width: 1000px) {
  .contract-dialog {
    .el-dialog {
      margin: 17vw auto 5vw !important;
    }
    .el-dialog__body {
      padding-top: 0;
    }
  }
}
</style>
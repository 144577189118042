<template>
  <div
    :class="[
      'list' + itemLen,
      mode == 'supplier' ? 'hot-servers-shop' : 'hot-servers-list',
    ]"
  >
    <div class="content">
      <div class="title flex_b flex_align_c">
        <h3 v-if="mode == 'supplier'">推荐服务商 <span>SERVICES</span></h3>
        <h3 v-if="mode == 'servers'">推荐需求 <span>SERVICES</span></h3>
      </div>
      <div class="list" v-if="mode == 'supplier'">
        <a
          :href="`https://www.huixianggongye.com/f/shop-${item.companyId}.html`"
          target="_blank"
          v-for="item in datalist"
          :key="item.id"
          class="item"
        >
          <el-image :src="item.shopMainImage" fit="cover" lazy></el-image>
          <div class="info">
            <div class="site">
              <div class="view">
                <img src="@/assets/images/public/hot.png" alt="" />
                {{ item.viewNum || 0 }}
              </div>
              <div class="site-r" v-if="item.area">
                <i class="iconfont icon-dizhi1"></i>{{ item.area.treeNames }}
              </div>
            </div>
            <div class="shop-info">
              <el-image :src="item.logo" fit="cover" lazy></el-image>
              <div class="shop-info-r" v-if="item.yzUserInfoCount">
                <h3>{{ item.name }}</h3>
                <p>
                  成交额：{{ item.yzUserInfoCount.alerdyServiceTotalMoney }}元 |
                  好评率：{{ item.yzUserInfoCount.goodRate }}%
                </p>
              </div>
            </div>
            <h3>{{ item.keywords }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </a>
      </div>
      <div class="list" v-if="mode == 'servers'">
        <a
          :href="`https://www.huixianggongye.com/f/shopservie-view-${item.cropCode}-${item.id}.html`"
          target="_blank"
          v-for="item in datalist"
          :key="item.id"
          class="item"
        >
          <el-image :src="item.firstImage" fit="cover" lazy></el-image>
          <div class="info">
            <div class="site">
              <div class="view">
                <img src="@/assets/images/public/hot.png" alt="" />
                {{ item.viewNum || 0 }}
              </div>
            </div>
            <h3>{{ item.name }}</h3>
            <p>{{ item.remarks || item.name }}</p>
            <div class="label" v-if="item.yzShopMainNeedTags.length">
              <span v-for="items in item.yzShopMainNeedTags" :key="items">{{
                yzCompanyAuthItemsJson[items]
              }}</span>
            </div>
            <div class="label" v-if="!item.yzShopMainNeedTags.length">
              <span>{{ item.yzShopCategory.categoryName }}</span>
              <span>{{ item.yzTaskCategory.name }}</span>
            </div>
            <div class="price flex_b flex_align_c">
              <span>￥{{ item.marketPrice }}</span>
              <p>了解详情</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mode: {
      default: "servers",
      type: String,
    },
    itemLen: {
      default: "3",
      type: String,
    },
  },
  data() {
    return {
      datalist: [],
      yzCompanyAuthItemsJson: {},
    };
  },
  mounted() {
    if (this.mode == "supplier") {
      //推荐服务商
      this.getYzShopInfoRecommendList();
    } else {
      //服务标签
      this.$sapi
        .get("/api/v1/getDictDataList-yz_shop_main_need_tags.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzCompanyAuthItemsJson[item.dictValue] = item.dictLabel;
          });
          //获取推荐服务
          this.getYzShopMainList();
        });
    }
  },
  methods: {
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shop-${item.companyId}.html`;
    },
    getYzShopInfoRecommendList() {
      this.$api
        .get("/api/v1/getYzShopInfoRecommendList", {
          params: {
            pageNo: 1,
            pageSize: 12,
          },
        })
        .then((res) => {
          this.datalist = res.list || res.data.list;
        });
    },
    getYzShopMainList() {
      this.$api
        .get("/api/v1/getYzShopMainList", {
          params: {
            pageNo: 1,
            pageSize: 12,
          },
        })
        .then((res) => {
          this.datalist = (res.list || res.data.list).map((item) => {
            item.yzShopMainNeedTags = item.yzShopMainNeedTags
              ? item.yzShopMainNeedTags.split(",")
              : [];
            return item;
          });
        });
    },
  },
};
</script>

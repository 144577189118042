<template>
  <div class="item" @click="goDetail(item)">
    <div class="date">{{ item.taskUserName }} {{ item.createDate }}发布</div>
    <div class="info">
      <div class="title flex_b">
        <span :title="item.title">{{ item.title }}</span>
        <div>
          <template v-for="(items, indexs) in item.yzTaskTags">
            <span :key="items" v-if="indexs < 3">{{
              yzTaskTagsJson[items]
            }}</span>
          </template>
        </div>
      </div>
      <div class="price">
        ￥{{ item.budgetPrice }} {{ yzTaskStausJson[item.yzTaskStaus] }}
      </div>
      <div class="desc">
        {{ item.description }}
      </div>
    </div>
    <div class="btn flex_b flex_align_c">
      <span
        >{{ item.assigmentCount }}人参与，剩{{
          item.needServerUserCount - item.assigmentCount
        }}个名额</span
      >
      <div class="common-btn">{{!item.taskEvaluationUser?'我要投标':'已中标'}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      default: {},
      type: Object,
    },
    yzTaskStausJson: {
      default: {},
      type: Object,
    },
    yzTaskTagsJson: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBid(item) {
      let params = {
        yzTaskMainId: item.id,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/partakeTaskMain", this.$common.getFormData(params))
        .then((res) => {
          if (res.code == "1") {
            this.$api
              .post(
                "/api/v1/updateAssigmentCountCountAddOne",
                this.$common.getFormData({ id: item.id })
              )
              .then((res) => console.log(res.message));
            this.$message({
              message: res.message,
              type: "success",
              duration: 1500,
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
    },
    goDetail(item) {
      location.href = "/service/detail?id=" + item.id;
    },
  },
};
</script>
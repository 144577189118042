<template>
  <div class="pay-main">
    <div class="tip" v-if="money">当前已托管金额{{ money }}元</div>
    <el-form
      v-if="!codeUrl && !this.bankForm.payStepId"
      :model="payForm"
      ref="payForm"
      label-position="top"
      class="pay-form"
      size="small"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item
            prop="price"
            label="托管金额（元）"
            :rules="[
              {
                required: true,
                message: '请输入本次托管金额',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="number"
              v-model="payForm.price"
              placeholder="请输入本次托管金额"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col
          :span="11"
          :offset="$store.state.isWap ? 1 : 0"
          v-if="yzTaskOrderPlanList.length"
        >
          <el-form-item prop="yzTaskOrderPlanId" label="选择项目规划">
            <el-select v-model="payForm.yzTaskOrderPlanId" placeholder="请选择">
              <el-option
                v-for="item in yzTaskOrderPlanList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-form-item
        prop="yzOrderPayStepName"
        label="款项标签"
        :rules="[
          {
            required: true,
            message: '请选择款项标签',
            trigger: 'change',
          },
        ]"
      >
        <el-radio-group v-model="payForm.yzOrderPayStepName">
          <el-radio
            :label="item.dictValue"
            v-for="item in yzInvoiceTypeList"
            :key="item.dictValue"
            >{{ item.dictLabel }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        prop="userCouponsId"
        label="优惠券"
        v-if="couponsList.length"
      >
        <el-button
          type="primary"
          style="padding: 9px 20px"
          icon="el-icon-discount"
          @click="couponsShow = true"
          v-if="!checkCoupon.id"
          >选择优惠券</el-button
        >
        <div class="coupons-list solo" v-if="checkCoupon.id !== undefined">
          <div class="item">
            <div class="l">
              <div class="title">{{ checkCoupon.name }}</div>
              <div class="date">
                满{{ checkCoupon.yzCmsCoupons.limitPrice }}使用
              </div>
              <div class="date">
                有效期至：{{ checkCoupon.endTime.split(" ")[0] }}
              </div>
            </div>
            <div class="r">
              <div class="price">
                ￥<span>{{ checkCoupon.yzCmsCoupons.coursePrice }}</span>
              </div>
              <div class="btn" @click="checkCoupon = {}">取消使用</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="remarks" label="备注">
        <el-input
          type="textarea"
          v-model="payForm.remarks"
          rows="2"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="payType"
        label="付款方式"
        :rules="[
          {
            required: true,
            message: '请选择付款方式',
            trigger: 'change',
          },
        ]"
      >
        <el-radio-group v-model="payForm.payType">
          <el-radio
            :label="item.dictValue"
            v-for="item in yzCashoutType"
            :key="item.dictValue"
            >{{ item.dictLabel }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <p class="hint">
          1、惠享网将保障您的资金安全，您验收通过后平台才会把钱支付给服务商。<br />
          2、对公转账，请将交易凭证上产至支付窗口。<br />
          3、为了您的资金安全，请勿线下交易。
        </p>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="payLoad" @click="goPay"
          >立即支付</el-button
        >
      </el-form-item>
    </el-form>
    <div class="code" v-if="codeUrl">
      <img :src="codeUrl" alt="" />
      <p>请扫码支付</p>
    </div>
    <div class="bank" v-if="this.bankForm.payStepId">
      <el-form
        :model="bankForm"
        ref="bankForm"
        label-position="top"
        class="bank-form"
        size="small"
      >
        <el-form-item>
          <div class="item" v-for="item in bankList" :key="item.id">
            {{ item.remarks }}
          </div>
        </el-form-item>
        <el-form-item
          prop="fileUploadId"
          label="线下支付凭证"
          :rules="[
            {
              required: true,
              message: '请上传线下支付凭证',
              trigger: 'blur',
            },
          ]"
        >
          <upload
            mode="btn"
            ref="upload"
            uploadType="all"
            format="pdf/png/jpg/jpeg"
            :fileName.sync="bankForm.fileUploadId"
            @success="upSuccess"
            @removeFileAll="removeFileAll"
            :multiple="false"
          />
        </el-form-item>

        <el-form-item prop="remarks" label="备注">
          <el-input
            type="textarea"
            rows="3"
            v-model="bankForm.remarks"
            placeholder="请添加备注"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="payLoad" @click="goPay2"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :visible.sync="couponsShow"
      width="700px"
      :modal-append-to-body="true"
      :append-to-body="true"
      :lock-scroll="true"
      title="我的优惠券"
    >
      <div class="coupons-list">
        <div
          class="item"
          v-for="item in couponsList"
          :key="item.id"
          @click="checkCoupons(item)"
        >
          <div class="l">
            <div class="title">{{ item.name }}</div>
            <div class="date">满{{ item.yzCmsCoupons.limitPrice }}使用</div>
            <div class="date">有效期至：{{ item.endTime.split(" ")[0] }}</div>
          </div>
          <div class="r">
            <div class="price">
              ￥<span>{{ item.yzCmsCoupons.coursePrice }}</span>
            </div>
            <div class="btn">立即使用</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
    yzTaskOrderPlanList: {
      default() {
        return [];
      },
      type: Array,
    },
    objItem: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    return {
      bankForm: {
        fileUploadId: "",
        payStepId: "",
        remarks: "",
        id: "",
      },
      codeUrl: "",
      money: 0,
      dialogVisible: false,
      fileUrl: "",
      checkCoupon: {},
      invoiceLoad: false,
      payForm: {
        price: "",
        yzOrderPayStepName: "",
        remarks: "",
        userCouponsId: "",
        yzTaskOrderPlanId: "",
        payType: "",
      },
      yzInvoiceTypeList: [],
      couponsShow: false,
      couponsList: [],
      payLoad: false,
      yzCashoutType: [],
      bankList: [],
      yz_pay_status:{},
      loadUps:{}
    };
  },
  mounted() {
    //银行卡
    this.$sapi
      .get("/api/v1/getDictDataList-yz_enterpise_bankno.json")
      .then((res) => {
        this.bankList = [res[0]];
      });
    //付款方式
    this.$sapi
      .get("/api/v1/getDictDataList-yz_cashout_type.json")
      .then((res) => {
        this.yzCashoutType = res;
      });
    //付款标签
    // this.$sapi
    //   .get("/api/v1/getDictDataList-yz_order_pay_step_name.json")
    //   .then((res) => {
    //     this.yzInvoiceTypeList = res;
    //   });
    this.$api.post("/api/v1/getUserCouponsList").then((res) => {
      this.couponsList = res.data;
    });
    //项目内容
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_content.json")
      .then((res) => {
        this.yzInvoiceTypeList = res;
      });
    this.inits();
  },
  methods: {
    removeFileAll() {
      this.bankForm.fileUploadId = "";
    },
    goPay2() {
      this.$refs.bankForm.validate((valid) => {
        if (valid) {
          this.payLoad = true;
          this.$refs.upload.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    upSuccess(files) {
      if (files) {
        this.loadUps = this.$message({
          message: "正在处理文件，请不要关闭页面",
          iconClass: "el-icon-loading",
          type: "success",
          duration: 0,
        });
        this.bankForm.fileUploadId = files[0].fileUploadId;
        this.bankForm.id = this.objItem.id;
        this.$api
          .post(
            "/api/v1/uploadPayOfflineFiles",
            this.$common.getFormData(this.bankForm)
          )
          .then((res) => {
            this.payLoad = false;
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
            if (res.code == 1) {
              this.$emit("offlineSuccess");
              this.$api
                .post(
                  "/api/v1/taskUserSendPayMessageToCaiWu",
                  this.$common.getFormData({
                    yzTaskMain: this.yzTaskMainId,
                    yzTaskOrderPlan: this.objItem.id,
                    yzTaskOrderPayStep: this.bankForm.payStepId,
                  })
                )
                .then((res) => {
                  this.inits();
                })
                .catch((err) => {
                  this.inits();
                });
            }
            this.loadUps.close ? this.loadUps.close() : null;
          })
          .catch((err) => {
            this.payLoad = false;
            this.loadUps.close ? this.loadUps.close() : null;
          });
      }
    },
    inits() {
      this.bankForm = {
        fileUploadId: "",
        payStepId: "",
        remarks: "",
        id: "",
      };
      this.payForm = {
        price: "",
        yzOrderPayStepName: "",
        remarks: "",
        userCouponsId: "",
        yzTaskOrderPlanId: "",
        payType: "",
      };
      let { commitMoney, workContent, id } = this.objItem;
      if (commitMoney) {
        this.payForm.price = commitMoney;
        this.payForm.yzOrderPayStepName = workContent;
        this.payForm.yzTaskOrderPlanId = id;
      }
      this.$api
        .get(
          "/api/v1/selectUserAlerdySumCash?yzTaskMainId=" + this.yzTaskMainId
        )
        .then((res) => {
          this.money = res.data;
        });
    },
    notPay() {
      this.$emit("back");
    },
    checkCoupons(item) {
      this.couponsShow = false;
      this.checkCoupon = item;
    },
    hasPaySuccess(id) {
      this.$api
        .get("/api/v1/checkOrderPlanStatus?payStepId=" + id)
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
            this.$emit("success");
          } else {
            setTimeout((_) => {
              this.hasPaySuccess(id);
            }, 1000);
          }
        });
    },
    goPay() {
      this.$refs.payForm.validate((valid) => {
        if (valid) {
          if (this.payForm.price <= 0) {
            this.$message({
              message: "金额不得小于等于0！",
              type: "error",
            });
            return;
          }
          this.payLoad = true;
          this.payForm.userCouponsId =
            this.checkCoupon.id !== undefined ? this.checkCoupon.id : "";
          this.payForm.yzTaskMainId = this.yzTaskMainId;
          this.$api
            .post(
              "/api/v1/createPayOrder",
              this.$common.getFormData(this.payForm)
            )
            .then((res) => {
              this.payLoad = false;
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
                onClose: () => {
                  if (res.code == 1) {
                    if (this.payForm.payType == 2) {
                      var tempwindow = window.open("_blank");
                      tempwindow.location.href = res.data.pcRedirectPayUrl;
                      this.payForm = {
                        price: "",
                        yzOrderPayStepName: "",
                        remarks: "",
                        userCouponsId: "",
                        yzTaskOrderPlanId: "",
                      };
                      this.checkCoupon = {};
                    } else if (this.payForm.payType == 3) {
                      this.hasPaySuccess(res.data.id);
                      this.$api.get(res.data.pcRedirectPayUrl).then((res) => {
                        this.codeUrl = res.data;
                      });
                    } else {
                      this.bankForm.payStepId = res.data.id;
                    }
                  }
                },
              });
            })
            .catch((err) => {
              this.payLoad = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<template>
  <div class="quality-main">
    <div
      class="time"
      v-if="!isOver && commentList.length == 0 && detail.yzTaskStaus == 12"
    >
      反馈倒计时：<span>{{ overDate.d }}</span
      >天<span>{{ overDate.h }}</span
      >时<span>{{ overDate.m }}</span
      >分<span>{{ overDate.s }}</span
      >秒
    </div>
    <el-form
      v-if="detail.yzTaskStaus == 12"
      :model="supplementForm"
      ref="supplementForm"
      label-position="top"
      class="pay-form"
      size="small"
    >
      <el-form-item
        prop="description"
        label="反馈内容"
        :rules="[
          {
            required: true,
            message: '请留言',
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          type="textarea"
          v-model="supplementForm.description"
          rows="5"
          placeholder="请留言"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="filesName"
        label="反馈资料"
        :rules="[
          {
            required: true,
            message: '请上传反馈资料',
            trigger: 'change',
          },
        ]"
      >
        <upload
          ref="supplementUpLoad"
          uploadType="all"
          format="pdf/png/jpg/jpeg"
          :fileName.sync="supplementForm.filesName"
          @success="supplementSuccess"
        />
      </el-form-item>
      <el-form-item class="btn">
        <el-button
          type="success"
          icon="el-icon-circle-check"
          :loading="yesLoad"
          @click="goYes"
          v-if="!hasShop"
          >产品合格，确认验收</el-button
        >
        <el-button
          type="primary"
          :loading="supplementLoad"
          @click="goSupplement"
          >提交反馈</el-button
        >
      </el-form-item>
    </el-form>
    <div class="xcp-list">
      <div class="xcp-list-list">
        <div>
          <template v-for="item in commentList">
            <div class="xcp-item" :key="item.id" v-if="!item.isDel">
              <div class="left">
                <div class="x-avatar">
                  <el-image
                    :src="
                      item.createUser.avatar
                        ? 'https://www.huixianggongye.com' + item.createUser.avatar
                        : require('@/assets/images/public/servers.png')
                    "
                    lazy
                    fit="cover"
                  ></el-image>
                </div>
              </div>
              <div class="right">
                <div class="user-bar">
                  <h5 class="user-bar-uname">
                    {{ item.createUser.userName || "匿名用户" }}
                    <font>{{ item.updateDate }}</font>
                    <!-- <span
                      @click="del(item)"
                      v-if="$store.state.userInfo.userCode == item.createBy"
                      ><i class="el-icon-delete"></i>删除</span
                    > -->
                  </h5>
                </div>

                <div class="x-interact-rich-text rich-text">
                  <span class="type-text" v-html="item.content"></span>
                </div>
                <div
                  class="files"
                  v-if="item.listFiles && item.listFiles.length"
                >
                  <div class="files-title">附件：</div>
                  <div
                    class="files-item"
                    v-for="items in item.listFiles"
                    :key="items.id"
                  >
                    {{ items.fileName }}
                    <a
                      target="_blank"
                      :href="`https://static.huixianggongye.com/fileupload/${items.fileEntity.filePath}${items.fileEntity.fileId}.${items.fileEntity.fileExtension}`"
                      >预览</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="not-data" v-if="commentList.length == 0">无反馈数据</div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
export default {
  components: { upload },
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    overDate: {
      type: Object,
      default: { d: 0, h: 0, m: 0, s: 0 },
    },
    detail: {
      type: Number | String,
      default: 0,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.createBy ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  data() {
    return {
      yesLoad: false,
      moreText: "查看更多评论",
      dataLoad: 0,
      supplementForm: {
        description: "",
        filesName: "",
      },
      supplementLoad: false,
      commentList: [],
      loadUps: {},
    };
  },
  mounted() {
    this.$emit("getContractInfo");
    this.inits();
  },
  methods: {
    goYes() {
      this.$confirm(`该操作将结束订单，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .post(
            "/api/v1/makeSureYzCmsIssueScore",
            this.$common.getFormData({
              taskMainId: this.yzTaskMainId,
            })
          )
          .then((res) => {
            this.$message({
              message: res.baseResultInfo.message,
              type: res.baseResultInfo.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {},
            });
          });
      });
    },
    //上传补充附件
    goSupplement() {
      this.$refs.supplementForm.validate((valid) => {
        if (valid) {
          this.supplementLoad = true;
          if (this.supplementForm.filesName) {
            this.$refs.supplementUpLoad.submit();
          } else {
            this.goSaveSupplement();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交补充资料
    goSaveSupplement(files = []) {
      this.$api
        .post(
          "/api/v1/saveYzCmsIssueScore",
          this.$common.getFormData({
            leaveMessage: this.supplementForm.description,
            taskMainId: this.yzTaskMainId,
            fileUploadIds: files.map((item) => item.fileUploadId).join(","),
          })
        )
        .then((res) => {
          this.supplementLoad = false;
          if (res.baseResultInfo.code == 1) {
            this.dataLoad = 0;
            this.getData();
            this.supplementForm = {
              description: "",
              filesName: "",
            };
            this.$refs.supplementUpLoad.clear();
          }
          this.$message({
            message: res.baseResultInfo.message,
            type: res.baseResultInfo.code == 1 ? "success" : "error",
            duration: 1500,
          });
          this.loadUps.close ? this.loadUps.close() : null;
        })
        .catch((err) => {
          this.loadUps.close ? this.loadUps.close() : null;
          this.supplementLoad = false;
        });
    },
    //需求资料上传成功
    supplementSuccess(files) {
      if (files) {
        this.loadUps = this.$message({
          message: "正在处理文件，请不要关闭页面",
          iconClass: "el-icon-loading",
          type: "success",
          duration: 0,
        });
        this.goSaveSupplement(files);
      } else {
        this.goSaveSupplement();
      }
    },
    getData() {
      if (this.dataLoad == 2) return;
      this.dataLoad = 1;
      this.$api
        .get("/api/v1/getYzCmsIssueScoreList", {
          params: {
            taskMainId: this.yzTaskMainId,
            pageNo: this.pageIndex,
            pageSize: 999,
          },
        })
        .then((res) => {
          this.dataLoad = 0;
          this.commentList = res.baseResultInfo.data.list;
          if (
            res.baseResultInfo.data.list.length <
            res.baseResultInfo.data.pageSize
          ) {
            this.moreText = "没有更多了";
            this.dataLoad = 2;
          } else {
            this.moreText = "查看更多评论";
            this.pageIndex++;
          }
        })
        .catch((err) => {
          this.dataLoad = 2;
        });
    },
    inits() {
      this.getData();
    },
  },
};
</script>
<style lang="scss" >
.xcp-list {
  * {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  &-title {
    margin: 30px 0 0;
    font-size: 20px;
    line-height: 1;
    color: #333333;
    font-weight: 500;
  }
  &-loader {
    margin-top: 64px;
    height: 42px;
    font: 14px PingFangSC-Regular;
    text-align: center;
    line-height: 42px;
    color: #f5f5f5;
    border-radius: 6px;
    cursor: pointer;
    &.no-more {
      background: transparent;
    }
    &.is-second {
      margin-top: 30px;
      padding-left: 55px;
      height: auto;
      text-align: left;
      font: 13px/1 PingFangSC-Semibold;
      font-size: 13px;
      color: #f5f5f5;
      background: transparent;
    }
    i {
      margin-left: 5px;
    }
  }
  .x-avatar {
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    .el-image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user-bar-uname {
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: 5px;
    font {
      float: right;
      font-weight: 500;
      color: #999999;
    }
    span {
      margin-left: 10px;
      cursor: pointer;
      i {
        margin-right: 5px;
      }
      &:hover {
        color: $c2;
      }
    }
  }
  .files {
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .files-title {
      margin-top: 5px;
    }
    &-item {
      margin-top: 5px;
      margin-right: 10px;
      a {
        color: $c2;
        text-decoration: underline;
      }
    }
  }
}
.x-avatar.is-small {
  width: 39px;
  height: 39px;
}
.xplaceholder-avatar- {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(https://b.bdstatic.com/searchbox/icms/searchbox/img/xcp-bd-logo.png)
    50% 50% no-repeat;
  background-size: 50% 50%;
}
.x-avatar-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.x-avatar-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 300%;
  height: 300%;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  -webkit-transform: scale(0.333333);
  transform: scale(0.333333);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.x-avatar-vip {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 16.5px;
  height: 16.5px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.x-avatar .is-small-vip {
  width: 13.2px;
  height: 13.2px;
}
.xcp-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 14px 0 0;
  font-size: 14px;
  color: #333333;
}
.xcp-item .left {
  width: 64px;
}
.is-second .xcp-item .left {
  width: 55px;
}
.xcp-item .right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 1%;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 14px;
}
.is-second .xcp-item {
  padding-top: 28px;
}
.x-interact-rich-text {
  padding-top: 10px;
  text-align: justify;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
}
.x-interact-rich-text > a {
  text-decoration: none;
}
.x-interact-rich-text .type-at,
.x-interact-rich-text .type-image,
.x-interact-rich-text .type-link,
.x-interact-rich-text .type-topic,
.x-interact-rich-text .type-video,
.x-interact-rich-text .type-vote {
  color: #4367b4;
  cursor: pointer;
}
.xcp-list-loader {
  margin-top: 64px;
  height: 42px;
  font: 14px PingFangSC-Regular;
  text-align: center;
  line-height: 42px;
  color: #333333;
  border-radius: 6px;
  cursor: pointer;
}
.xcp-list-loader.no-more {
  background: transparent;
}
.xcp-list-loader.is-second {
  margin-top: 30px;
  padding-left: 55px;
  height: auto;
  text-align: left;
  font: 13px/1 PingFangSC-Semibold;
  font-size: 13px;
  color: #f5f5f5;
  background: transparent;
}
.xcp-list-loader i {
  margin-left: 5px;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quality-main"},[(!_vm.isOver && _vm.commentList.length == 0 && _vm.detail.yzTaskStaus == 12)?_c('div',{staticClass:"time"},[_vm._v(" 反馈倒计时："),_c('span',[_vm._v(_vm._s(_vm.overDate.d))]),_vm._v("天"),_c('span',[_vm._v(_vm._s(_vm.overDate.h))]),_vm._v("时"),_c('span',[_vm._v(_vm._s(_vm.overDate.m))]),_vm._v("分"),_c('span',[_vm._v(_vm._s(_vm.overDate.s))]),_vm._v("秒 ")]):_vm._e(),(_vm.detail.yzTaskStaus == 12)?_c('el-form',{ref:"supplementForm",staticClass:"pay-form",attrs:{"model":_vm.supplementForm,"label-position":"top","size":"small"}},[_c('el-form-item',{attrs:{"prop":"description","label":"反馈内容","rules":[
        {
          required: true,
          message: '请留言',
          trigger: 'blur',
        },
      ]}},[_c('el-input',{attrs:{"type":"textarea","rows":"5","placeholder":"请留言"},model:{value:(_vm.supplementForm.description),callback:function ($$v) {_vm.$set(_vm.supplementForm, "description", $$v)},expression:"supplementForm.description"}})],1),_c('el-form-item',{attrs:{"prop":"filesName","label":"反馈资料","rules":[
        {
          required: true,
          message: '请上传反馈资料',
          trigger: 'change',
        },
      ]}},[_c('upload',{ref:"supplementUpLoad",attrs:{"uploadType":"all","format":"pdf/png/jpg/jpeg","fileName":_vm.supplementForm.filesName},on:{"update:fileName":function($event){return _vm.$set(_vm.supplementForm, "filesName", $event)},"update:file-name":function($event){return _vm.$set(_vm.supplementForm, "filesName", $event)},"success":_vm.supplementSuccess}})],1),_c('el-form-item',{staticClass:"btn"},[(!_vm.hasShop)?_c('el-button',{attrs:{"type":"success","icon":"el-icon-circle-check","loading":_vm.yesLoad},on:{"click":_vm.goYes}},[_vm._v("产品合格，确认验收")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","loading":_vm.supplementLoad},on:{"click":_vm.goSupplement}},[_vm._v("提交反馈")])],1)],1):_vm._e(),_c('div',{staticClass:"xcp-list"},[_c('div',{staticClass:"xcp-list-list"},[_c('div',[_vm._l((_vm.commentList),function(item){return [(!item.isDel)?_c('div',{key:item.id,staticClass:"xcp-item"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"x-avatar"},[_c('el-image',{attrs:{"src":item.createUser.avatar
                      ? 'https://www.huixianggongye.com' + item.createUser.avatar
                      : require('@/assets/images/public/servers.png'),"lazy":"","fit":"cover"}})],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"user-bar"},[_c('h5',{staticClass:"user-bar-uname"},[_vm._v(" "+_vm._s(item.createUser.userName || "匿名用户")+" "),_c('font',[_vm._v(_vm._s(item.updateDate))])],1)]),_c('div',{staticClass:"x-interact-rich-text rich-text"},[_c('span',{staticClass:"type-text",domProps:{"innerHTML":_vm._s(item.content)}})]),(item.listFiles && item.listFiles.length)?_c('div',{staticClass:"files"},[_c('div',{staticClass:"files-title"},[_vm._v("附件：")]),_vm._l((item.listFiles),function(items){return _c('div',{key:items.id,staticClass:"files-item"},[_vm._v(" "+_vm._s(items.fileName)+" "),_c('a',{attrs:{"target":"_blank","href":`https://static.huixianggongye.com/fileupload/${items.fileEntity.filePath}${items.fileEntity.fileId}.${items.fileEntity.fileExtension}`}},[_vm._v("预览")])])})],2):_vm._e()])]):_vm._e()]})],2)])]),(_vm.commentList.length == 0)?_c('div',{staticClass:"not-data"},[_vm._v("无反馈数据")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
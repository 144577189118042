<template>
  <div class="step">
    <div class="content">
      <div class="step-main">
        <div class="time" v-if="!isOver">
          投标报价时间：<span>{{ overDate.d }}</span
          >天<span>{{ overDate.h }}</span
          >时<span>{{ overDate.m }}</span
          >分<span>{{ overDate.s }}</span
          >秒
        </div>
        <el-steps
          :direction="!isWap ? 'horizontal' : 'vertical'"
          :active="yzTaskSteps"
          align-center
        >
          <el-step
            v-for="item in steps"
            :key="item.id"
            :title="item.title"
            :description="item.date"
          ></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    overDate: {
      type: Object,
      default: { d: 0, h: 0, m: 0, s: 0 },
    },
    detail: {
      type: Number | String,
      default: 0,
    },
    isOver: {
      type: Boolean,
      default: false,
    },
    yzTaskSteps: {
      type: Number | String,
      default: 0,
    },
    steps: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      yzTaskStepJson: {},
      isWap: false,
    };
  },
  mounted() {
    this.isWap = $(window).width() < 1000;
    this.$sapi.get("/api/v1/getDictDataList-yz_task_step.json").then((res) => {
      res.forEach((item) => {
        this.yzTaskStepJson[item.dictValue] = item.dictLabel;
      });
    });
  },
  methods: {
    inits() {},
  },
};
</script>
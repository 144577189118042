<template>
  <div class="order-main">
    <div class="table-list">
      <div class="list-l">
        <div class="btn">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus-outline"
            v-if="hasShop&&(detail.yzTaskStaus == 11||detail.yzTaskStaus == 17)"
            @click="addSchedule"
            >添加订单</el-button
          >
        </div>
        <el-form
          :model="forms"
          ref="tablelists"
          size="small"
          :class="['tablelists-form', !hasShop ? 'on' : '']"
        >
          <el-table
            size="small"
            :data="forms.list"
            style="width: 100%"
            border
            ref="multipleTable"
            empty-text=" "
            row-key="id"
            v-loading="tableLoad"
            fit
            :class="hasSort ? 'has-sort' : ''"
            highlight-current-row
            @current-change="handleCurrentChange"
          >
            <el-table-column
              prop="shippingNo"
              width="300"
              align="center"
              label="快递单号"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`list.${scope.$index}.shippingNo`"
                  :rules="{
                    required: !scope.row.preview && theId == scope.row.id,
                    message: '请输入快递单号',
                    trigger: 'change',
                  }"
                >
                  <el-input
                    v-if="!scope.row.preview"
                    placeholder="请输入快递单号"
                    v-model="scope.row.shippingNo"
                  ></el-input>
                  <p v-if="scope.row.preview">{{ scope.row.shippingNo }}</p>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="yzPostCompanyType"
              align="center"
              label="快递公司"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`list.${scope.$index}.yzPostCompanyType`"
                  :rules="{
                    required: !scope.row.preview && theId == scope.row.id,
                    message: '请选择快递公司',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="scope.row.yzPostCompanyType"
                    placeholder="请选择"
                    v-if="!scope.row.preview"
                    filterable
                    allow-create
                    default-first-option
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    >
                    </el-option>
                  </el-select>
                  <p v-if="scope.row.preview">
                    {{
                      typeJson[scope.row.yzPostCompanyType] ||
                      scope.row.yzPostCompanyType
                    }}
                  </p>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="workContent" align="center" label="项目内容">
            <template slot-scope="scope">
              <el-form-item
                :prop="`list.${scope.$index}.workContent`"
                :rules="{
                  required: !scope.row.preview && theId == scope.row.id,
                  message: '请输入项目内容',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-if="!scope.row.preview"
                  placeholder="请输入项目内容"
                  v-model="scope.row.workContent"
                ></el-input>
                <p v-if="scope.row.preview">{{ scope.row.workContent }}</p>
              </el-form-item>
            </template>
          </el-table-column> -->
            <!-- <el-table-column align="center" label="订单状态">
            <template slot-scope="scope">
              <el-form-item>
                <p>
                  <span
                    v-if="scope.row.yzShippingStatus !== undefined"
                    :class="['affirm-span']"
                    >{{ orderTypeJson[scope.row.yzShippingStatus]||'-' }}</span
                  >
                  <span v-else>-</span>
                </p>
              </el-form-item>
            </template>
          </el-table-column> -->
            <el-table-column align="center" label="发货状态">
              <template slot-scope="scope">
                <el-form-item>
                  <p>
                    <span
                      v-if="scope.row.yzShippingStatus !== undefined"
                      :class="['affirm-span']"
                      >{{ sendJson[scope.row.yzShippingStatus] || "-" }}</span
                    >
                    <span v-else>待发货</span>
                  </p>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              align="center"
              label="操作"
              v-if="(detail.yzTaskStaus == 11||detail.yzTaskStaus == 17)"
              :width="hasShop ? 500 : 250"
            >
              <template slot-scope="scope">
                <el-form-item>
                  <el-button
                    v-if="scope.row.preview && hasShop"
                    size="mini"
                    type="primary"
                    @click.stop="edit(scope.row)"
                    :loading="scope.row.saveLoad"
                  >
                    编辑
                  </el-button>
                  <el-button
                    v-if="!scope.row.preview && hasShop"
                    size="mini"
                    type="primary"
                    :loading="scope.row.saveLoad"
                    @click.stop="save(scope.row)"
                  >
                    保存
                  </el-button>
                  <el-button
                    v-if="hasShop"
                    size="mini"
                    :loading="scope.row.delLoad"
                    type="danger"
                    @click.stop="del(scope.row)"
                  >
                    删除
                  </el-button>
                  <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-message"
                    v-if="!scope.row.isNewRecord && hasShop"
                    @click.stop="goSend(scope.row, 1)"
                    >提醒客户已发货</el-button
                  >
                  <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-message"
                    v-if="!scope.row.isNewRecord && hasShop"
                    @click.stop="goSend(scope.row, 2)"
                    >提醒客户确认收货</el-button
                  >
                  <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-message"
                    v-if="!hasShop && scope.row.yzShippingStatus == 0"
                    @click.stop="goTips(scope.row)"
                    >提醒服务商发货</el-button
                  >
                  <el-button
                    type="success"
                    size="mini"
                    icon="el-icon-circle-check"
                    v-if="!hasShop && scope.row.yzShippingStatus == 1"
                    :loading="scope.row.saveLoad"
                    @click.stop="goGot(scope.row)"
                    >确认收货</el-button
                  >
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div class="loading" v-if="sendLoad">
        <span class="el-icon-loading"></span>加载中...
      </div>
      <div class="list-r" v-if="sendData.Traces && sendData.Traces.length">
        <div class="timeline-title" v-if="sendData.LogisticCode">
          <h3>快递单号：{{ sendData.LogisticCode }}</h3>
          <p>快递类型：{{ typeJson[sendData.ShipperCode] }}</p>
        </div>
        <el-timeline>
          <el-timeline-item
            type="primary"
            v-for="(activity, index) in sendData.Traces"
            :key="index"
            :timestamp="activity.AcceptTime"
          >
            {{ activity.AcceptStation }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>
<script>
import Snowflake from "@/common/snowflake";
export default {
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    detail: {
      default() {
        return {};
      },
      type: Object,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.evaluationUserId ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  data() {
    return {
      sendLoad: false,
      description: "",
      taskUserSureExecuteStatus: false,
      shoperUserSureExecuteStatus: false,
      hasSort: false,
      tableLoad: false,
      days: 0,
      activeName: "1",
      projectForm: { duration: "", filesName: "" },
      tableData: [],
      forms: { list: [] },
      stepList: [],
      stepJson: {},
      statusList: [],
      statusJson: {},
      theId: "",
      sortable: null,
      oldList: [],
      newList: [],
      sortKey: new Date().getTime(), // 为了方便拖动失败，回到拖动之前
      selectArray: [],
      commonStatus: {},
      yesLoad: false,
      notLoad: false,
      typeJson: {},
      typeList: [],
      orderTypeList: [],
      orderTypeJson: {},
      sendList: [],
      sendJson: {},
      sendData: { LogisticCode: "", ShipperCode: "", Traces: [] },
      sendLoad: false,
      currentRow: {},
    };
  },
  async mounted() {
    this.tableLoad = true;
    //快递类型
    await this.$sapi
      .get("/api/v1/getDictDataList-yz_post_company_type.json")
      .then((res) => {
        this.typeList = res;
        res.forEach((item) => {
          this.typeJson[item.dictValue] = item.dictLabel;
        });
      });
    //发货类型
    await this.$sapi
      .get("/api/v1/getDictDataList-yz_shipping_status.json")
      .then((res) => {
        this.sendList = res;
        res.forEach((item) => {
          this.sendJson[item.dictValue] = item.dictLabel;
        });
      });
    this.inits();
    // //订单类型
    // this.$sapi
    //   .get("/api/v1/getDictDataList-yz_order_status.json")
    //   .then((res) => {
    //     this.orderTypeList = res;
    //     res.forEach((item) => {
    //       this.orderTypeJson[item.dictValue] = item.dictLabel;
    //     });
    //   });
  },
  methods: {
    goTips(row) {
      this.$api
        .post(
          "/api/v1/taskUserSendNoticeGoodMessage",
          this.$common.getFormData({
            yzTaskOrderPostId: row.id,
          })
        )
        .then((res) => {
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        });
    },
    goGot(row) {
      this.$confirm(`请确认订单${row.shippingNo}已收货吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.forms.list = this.forms.list.map((item) => {
            if (item.id == row.id) {
              item.saveLoad = true;
            }
            return item;
          });
          this.$api
            .post(
              "/api/v1/taskUserSendReceiveGoodMessage",
              this.$common.getFormData({
                yzTaskOrderPostId: row.id,
              })
            )
            .then((res) => {
              this.forms.list = this.forms.list.map((item) => {
                if (item.id == row.id) {
                  item.saveLoad = false;
                }
                return item;
              });
              if (res.code == 1) {
                this.inits();
                this.$emit("getNewData");
              }
              this.$message({
                message: res.message,
                type: res.code == 1 ? "success" : "error",
                duration: 1500,
              });
            })
            .catch((err) => {
              this.forms.list = this.forms.list.map((item) => {
                if (item.id == row.id) {
                  item.saveLoad = false;
                }
                return item;
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCurrentChange(val) {
      if (val.isNewRecord) return;
      this.currentRow = val;
      this.sendData = { LogisticCode: "", ShipperCode: "", Traces: [] };
      this.goSendInfo(val);
    },
    goSendInfo(row) {
      this.sendLoad = true;
      this.$api
        .post(
          "/api/v1/searchPostCode",
          this.$common.getFormData({
            postCode: row.shippingNo,
            postCodeType: row.yzPostCompanyType,
          })
        )
        .then((res) => {
          this.sendLoad = false;
          try {
            this.sendData = JSON.parse(res.data);
            console.log(this.sendData);
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          this.sendLoad = false;
        });
    },
    goSend(row, mode) {
      this.$api
        .post(
          mode == 1
            ? "/api/v1/sendYzTaskOrderPlanPostCodeMessage"
            : "/api/v1/shopUserSendReceiveGoodMessageNotice",
          this.$common.getFormData(
            mode == 1
              ? {
                  postCode: row.shippingNo,
                  yzTaskMainId: this.yzTaskMainId,
                }
              : {
                  yzTaskOrderPostId: row.id,
                }
          )
        )
        .then((res) => {
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        });
    },
    save(row) {
      this.theId = row.id;
      this.forms.list = this.forms.list.map((item) => {
        if (item.id == row.id) {
          item.saveLoad = true;
        }
        return item;
      });
      this.$nextTick(() => {
        this.$refs.tablelists.validate((valid) => {
          if (valid) {
            this.$api
              .post(
                "/api/v1/saveTaskOrderPost",
                this.$common.getFormData({
                  ...row,
                  yzTaskMainId: this.yzTaskMainId,
                })
              )
              .then((res) => {
                this.forms.list = this.forms.list.map((item) => {
                  if (item.id == row.id) {
                    item.saveLoad = false;
                  }
                  return item;
                });
                if (res.code == 1) {
                  this.inits();
                }
                this.$message({
                  message: res.message,
                  type: res.code == 1 ? "success" : "error",
                  duration: 1500,
                });
              })
              .catch((err) => {
                this.forms.list = this.forms.list.map((item) => {
                  if (item.id == row.id) {
                    item.saveLoad = false;
                  }
                  return item;
                });
              });
          }
        });
      });
    },
    edit(row) {
      this.theId = row.id;
      this.forms.list = this.forms.list.map((item) => {
        if (item.id == row.id) {
          item.preview = !item.preview;
        }
        return item;
      });
    },
    del(row) {
      let { id } = row;
      if (row.isNewRecord) {
        this.forms.list = this.forms.list.filter((item) => {
          return item.id != id;
        });
      } else {
        this.$confirm(`请确定删除${row.shippingNo}订单吗?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.theId = row.id;
          this.forms.list = this.forms.list.map((item) => {
            if (item.id == row.id) {
              item.delLoad = true;
            }
            return item;
          });
          row.status = 1;
          this.$api
            .post(
              "/api/v1/saveTaskOrderPost",
              this.$common.getFormData({
                ...row,
                yzTaskMainId: this.yzTaskMainId,
              })
            )
            .then((res) => {
              this.forms.list = this.forms.list.map((item) => {
                if (item.id == row.id) {
                  item.delLoad = false;
                }
                return item;
              });
              if (res.code == 1) {
                this.inits();
                this.$message({
                  message: "删除成功！",
                  type: res.code == 1 ? "success" : "error",
                  duration: 1500,
                });
              } else {
                this.$message({
                  message: res.message,
                  type: res.code == 1 ? "success" : "error",
                  duration: 1500,
                });
              }
            })
            .catch((err) => {
              this.forms.list = this.forms.list.map((item) => {
                if (item.id == row.id) {
                  item.delLoad = false;
                }
                return item;
              });
            });
        });
      }
    },
    addSchedule() {
      this.forms.list.push({
        id: new Snowflake(this.forms.list.length + 1, 1, 0).nextId(),
        yzPostCompanyType: "",
        shippingNo: "",
        editLoad: false,
        delLoad: false,
        saveLoad: false,
        isNewRecord: true,
        status: 0,
      });
    },
    getData() {
      this.tableLoad = true;
      this.$api
        .post(
          "/api/v1/getTaskOrderPost",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          this.tableLoad = false;
          if (res.data && res.data.length) {
            this.forms.list = res.data.map((item) => {
              item.editLoad = false;
              item.delLoad = false;
              item.saveLoad = false;
              item.preview = true;
              return item;
            });
            if (this.forms.list.length) {
              this.$refs.multipleTable.setCurrentRow(this.forms.list[0]);
            }
          }
        });
    },
    inits() {
      this.getData();
    },
  },
};
</script>
<style lang="scss">
.picker {
  .el-range-editor {
    width: 100%;
  }
  .el-range-separator {
    width: 10px;
  }
  .el-range-editor--small .el-range-input {
    width: 100%;
  }
}
.order-main {
  .el-form-item__content {
    font-size: 14px;
  }
  .tablelists-form {
    &.on {
      .el-form-item {
        margin: 5px 0;
      }
    }
    .el-table th > .cell {
      font-size: 14px;
    }
    .has-sort tr {
      cursor: all-scroll;
    }
    .el-table {
      .el-form-item {
        .el-input__inner {
          padding-left: 5px;
          padding-right: 5px;
        }
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
    .el-form-item {
      margin: 15px 0;
      .el-form-item__error {
        padding-top: 1px;
      }
    }

    .el-table--enable-row-transition .el-table__body td {
      padding: 0;
    }
  }
}
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: fade-out($color: $c2, $amount: 0.9) !important;
}
.send-info {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
  .el-timeline {
    padding-left: 0;
  }
}
</style>
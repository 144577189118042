<template>
  <div class="detail-supplier-list">
    <div class="content">
      <div class="common-title">
        <h3>服务商列表 <span>SERVICES</span></h3>
      </div>
      <div class="list" v-if="list.length">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="item-t">
            <el-image
              :src="item.yzViewShopInfo.logo"
              @click="goDetail(item)"
              fit="cover"
             lazy></el-image>
            <div class="t-info" @click="goDetail(item)">
              <div class="title">
                <span class="title-text">{{ item.yzViewShopInfo.name }}</span>
                <span
                  class="common-label"
                  v-for="items in item.yzViewShopInfo.yzCompanyAuthItems"
                  :key="items"
                  >{{ yzCompanyAuthItemsJson[items] }}</span
                >
              </div>
              <div class="line">
                <p>
                  已服务企业：<span
                    >{{ item.yzViewShopInfo.orderTotalCount }}家</span
                  >
                </p>
                <p>
                  成交额：<span
                    >￥{{ item.yzViewShopInfo.alerdyServiceTotalMoney }}</span
                  >
                </p>
              </div>
              <div class="desc">
                擅长：{{ item.yzViewShopInfo.hobbyname }}。
              </div>
            </div>
            <div class="btn" v-if="item.isSelect == 1">
              <p
                class="common-btn"
                v-if="
                  isUser &&
                  (!detail.yzViewShopInfo || !detail.yzViewShopInfo.cropName)
                "
                @click="checkUser(item)"
              >
                选TA中标
              </p>
              <p
                class="common-btn"
                v-if="
                  isUser &&
                  (!detail.yzViewShopInfo || !detail.yzViewShopInfo.cropName)
                "
                @click="outUser(item)"
              >
                淘汰TA
              </p>
            </div>
            <!-- 中标状态  1 招标中 2 已中标 3 已废标 4 未中标 5 已完成  6 招标超时 -->
            <div class="btn solo" v-if="item.isSelect == 2">
              <p class="common-btn" v-if="isUser" @click="closeUser(item)">
                取消中标
              </p>
            </div>
            <div class="icon" v-if="item.isSelect == 2">
              <img src="@/assets/images/public/icon_yzb.png" alt="" />
            </div>
            <div class="icon" v-if="item.isSelect == 3">
              <img src="@/assets/images/public/icon_tt.png" alt="" />
            </div>
            <div
              class="icon"
              v-if="
                item.isSelect == 4 ||
                item.isSelect == 5 ||
                item.isSelect == 6 ||
                item.isSelect == 0
              "
            ></div>
            <a href="https://www.huixianggongye.com/f/contactKefu?toUser=0_yizao004_d4br" target="_blank" rel="noopener noreferrer"><i class="iconfont icon-liaotian"></i></a>
          </div>
          <div
            class="else"
            v-if="
              item.singilePrice &&
              ((userInfo.userCode == item.updateBy && roleCode!=1) || roleCode==1) &&
              userInfo.userCode
            "
          >
            <div class="else-item">
              <h3>总金额</h3>
              <p>￥{{ item.singilePrice }}</p>
            </div>
            <div class="else-item">
              <h3>工期</h3>
              <p>{{ item.days }} <span>天</span></p>
            </div>
            <div class="else-item">
              <h3>是否含税</h3>
              <p>{{ item.isFax == 1 ? "是" : "否" }}</p>
            </div>
            <div class="else-item">
              <h3>税点</h3>
              <p>{{ item.faxValue || 0 }} <span>%</span></p>
            </div>
            <div class="else-item">
              <h3>含税金额</h3>
              <p>￥{{ item.totalPrice }}</p>
            </div>
            <div class="else-item">
              <h3>报价单</h3>
              <a :href="item.files" target="_blank" rel="点击下载报价单"
                >点击下载报价单</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="not-data" v-if="!list.length">暂无服务商投标</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      default: [],
      type: Array,
    },
    taskUser: {
      default: {},
      type: Object,
    },
    detail: {
      default: { selectShoperYzTaskEvaluation: { evaluationUserId: "" } },
      type: Object,
    },
    yzCompanyAuthItemsJson: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    roleCode() {
      let roleCode = this.$store.state.userInfo.roleCode;
      if (roleCode) {
        if (roleCode.indexOf("member") >= 0) {
          //客户
         return 1;
        } else if (roleCode.indexOf("shoper") >= 0) {
          //服务商
          return 2;
        } else if (
          roleCode.indexOf("member") >= 0 &&
          roleCode.indexOf("shoper") >= 0
        ) {
          //多重角色
          return 3;
        }
      }
    },
    isUser() {
      let userInfo = this.$store.state.userInfo;
      return userInfo.userCode == this.taskUser.userCode;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.huixianggongye.com/f/shop-${item.companyId}.html`;
    },
    inits() {},
    closeUser(item) {
      this.$confirm(
        `您确定要取消${item.yzViewShopInfo.cropName}的中标吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$api
          .post(
            "/api/v1/unSelectPartakeTaskMain",
            this.$common.getFormData({
              yzTaskEvaluationId: item.id,
              yzTaskMainId: item.yzTaskMainId,
            })
          )
          .then((res) => {
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {
                this.$emit("inits");
              },
            });
          });
      });
    },
    checkUser(item) {
      this.$confirm(
        `您确定要选${item.yzViewShopInfo.cropName}为中标公司吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$api
          .post(
            "/api/v1/selectPartakeTaskMain",
            this.$common.getFormData({
              yzTaskEvaluationId: item.id,
              yzTaskMainId: item.yzTaskMainId,
            })
          )
          .then((res) => {
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {
                res.code == 1 ? this.$emit("inits") : null;
              },
            });
          });
      });
    },
    outUser(item) {
      this.$confirm(`您确定要淘汰${item.yzViewShopInfo.cropName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$api
          .post(
            "/api/v1/outPartakeTaskMain",
            this.$common.getFormData({
              yzTaskEvaluationId: item.id,
              yzTaskMainId: item.yzTaskMainId,
            })
          )
          .then((res) => {
            if (res.code == 1) {
              this.$api
                .post(
                  "/api/v1/updateTaobaiUserCountAddOne",
                  this.$common.getFormData({ id: item.yzTaskMainId })
                )
                .then((res) => console.log(res.message));
            }
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
              onClose: () => {
                res.code == 1 ? this.$emit("inits") : null;
              },
            });
          });
      });
    },
  },
};
</script>

<template>
  <div class="detail-info">
    <div class="content flex_b">
      <div class="info-l">
        <div class="t-flow">
          <div
            :class="[
              'item',
              yzTaskStep-1 < index ? 'on' : '',
            ]"
            v-for="(item, index) in yzTaskStausList"
            :key="item.dictValue"
          >
            <i class="iconfont icon-you2 s" v-if="index > 0"></i>
            {{ item.dictLabel }}
            <i
              class="iconfont icon-you2 e"
              v-if="index < yzTaskStausList.length - 1"
            ></i>
          </div>
        </div>
        <div class="b">
          <div class="b-item">
            <p v-if="detail.orderSn">
              <span>订单号：{{ detail.orderSn }}</span>
            </p>
            <p class="tip">
              用户{{ detail.nickname }}于<span>{{ detail.createDate }}</span
              >发布该需求
            </p>
            <div class="time" v-if="!isOver">
              投标剩余时间：<span>{{ overDate.d }}</span
              >天<span>{{ overDate.h }}</span
              >时<span>{{ overDate.m }}</span
              >分<span>{{ overDate.s }}</span
              >秒
            </div>
            <!-- <div class="time" v-if="isOver">已过期</div> -->
            <div class="label" v-if="detail.yzCompanyAuthItems">
              <font>雇主对服务商的要求：</font>
              <p>
                <span v-for="item in detail.yzCompanyAuthItems" :key="item">{{
                  yzCompanyAuthItemsJson[item]
                }}</span>
              </p>
            </div>
          </div>
          <div class="b-item">
            <p class="line">
              需求名称：<span>{{ detail.title }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              需求类型：<span v-if="detail.yzTaskCategory">{{
                detail.yzTaskCategory.name
              }}</span>
            </p>
          </div>
          <div class="b-item box">
            <p class="line">
              需求数量：<span>{{ detail.taskNum }}</span> 件
            </p>
            <p class="line">
              时间工期：<span>{{ detail.days }}天</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              预算金额：<span>￥{{ detail.budgetPrice }}</span>
            </p>
          </div>
          <div class="b-item" v-if="detail.yzTaskData.taskUserAddress">
            <p class="line">
              需求方收货地址：<span>{{
                detail.yzTaskData.taskUserAddress
              }}</span>
            </p>
          </div>
          <div class="b-item" v-if="detail.yzTaskData.shoperUserAddress">
            <p class="line">
              供应商收货地址：<span>{{
                detail.yzTaskData.shoperUserAddress
              }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              需求描述：
              <span>{{ detail.description }}</span>
            </p>
          </div>
          <div class="b-item">
            <p class="line">
              制造文档：<span>{{ taskFileTypes.join(",") }}</span>
            </p>
          </div>
          <div class="btns">
            <p
              class="common-btn r"
              v-if="!detail.currentShoperYzTaskEvaluation && !isUser"
              @click="goJoin"
            >
              参与需求
            </p>
            <p class="common-btn" @click="upVisible = true">发布需求</p>
            <p
              class="common-btn"
              v-if="!userInfo.userCode || (isNotMy && !isUser)"
              @click="goTip"
            >
              下载图纸
            </p>
            <a
              v-if="
                detail.designMainFile &&
                userInfo.userCode &&
                (!isNotMy || isUser)
              "
              :href="detail.designMainFile"
              target="_blank"
              class="common-btn"
              >下载图纸</a
            >
            <p class="common-btn" @click="goChat">在线沟通</p>
          </div>
        </div>
      </div>
      <div class="info-r">
        <make3d ref="make3ds" :boxRight="false" />
      </div>
    </div>
    <up-dialog :visible.sync="upVisible" />
  </div>
</template>
<script>
import upDialog from "@/components/servers/up-dialog";
import make3d from "@/components/make3d/index.vue";
export default {
  components: { make3d, upDialog },
  props: {
    detail: {
      type: Object,
      default: {},
    },
    overDate: {
      type: Object,
      default: { d: 0, h: 0, m: 0, s: 0 },
    },
    isOver: {
      type: Boolean,
      default: false,
    },
    yzTaskSteps: {
      type: String | Number,
      default: 1,
    },
  },
  data() {
    return {
      upVisible: false,
      yzTaskStausList: [
        { dictValue: 1, dictLabel: "招标" },
        { dictValue: 2, dictLabel: "工厂报价" },
        { dictValue: 3, dictLabel: "开始生产" },
      ],
      yzCompanyAuthItemsJson: {},
      isNotMy: true,
      filesTypeJson: {},
      taskFileTypes: [],
    };
  },
  computed: {
    roleCode() {
      let roleCode = this.$store.state.userInfo.roleCode;
      if (roleCode) {
        if (roleCode.indexOf("member") >= 0) {
          //客户
          return 1;
        } else if (roleCode.indexOf("shoper") >= 0) {
          //服务商
          return 2;
        } else if (
          roleCode.indexOf("member") >= 0 &&
          roleCode.indexOf("shoper") >= 0
        ) {
          //多重角色
          return 3;
        }
      }
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isUser() {
      let userInfo = this.$store.state.userInfo;
      return userInfo.userCode == this.detail.taskUser.userCode;
    },
    yzTaskStep(){
      let step = 1;
      if(this.yzTaskSteps<5&&this.yzTaskSteps>=3){
        step = 2;
      }
      if(this.yzTaskSteps>=5){
        step = 3;
      }
      return step;
    }
  },
  mounted() {
    this.$sapi
      .get("/api/v1/getDictDataList-yz_company_auth_items.json")
      .then((res) => {
        res.forEach((item) => {
          this.yzCompanyAuthItemsJson[item.dictValue] = item.dictLabel;
        });
      });
  },
  methods: {
    goTip() {
      this.$message({
        message: "请先参与投标，才能下载图纸",
        type: "error",
      });
    },
    inits() {
      if (this.detail.designMainFile && (this.roleCode != 1 || this.isUser)) {
        let url = this.detail.designMainFile.replace(
          "https://static.huixianggongye.com",
          window.config.dapi
        );
        var nameArray = this.detail.designMainFile.split(".");
        var type = nameArray[nameArray.length - 1];
        if (type.toLowerCase() == "stp" || type.toLowerCase() == "step") {
          this.$refs.make3ds.showView(
            url.replace(".stp", ".stl").replace(".step", ".stl")
          );
        } else {
          this.$refs.make3ds.showView(url);
        }
      }
    },
    goResize() {
      this.$refs.make3ds.goResize();
      let userInfo = this.$store.state.userInfo;
      if (this.detail.listYzTaskEvaluation) {
        let obj = this.detail.listYzTaskEvaluation.find(
          (item) => item.updateBy == userInfo.userCode
        );
        this.isNotMy = !obj;
      }
      this.inits();
      $(".make3d").height($(".make3d").height());
      this.$sapi
        .get("/api/v1/getDictDataList-yz_task_files_type.json")
        .then((res) => {
          res.forEach((item) => {
            this.filesTypeJson[item.dictValue] = item.dictLabel;
          });
          this.taskFileTypes = this.detail.taskFileTypes
            ? this.detail.taskFileTypes.split(",").map((item) => {
                return this.filesTypeJson[item];
              })
            : [];
        });
    },
    goJoin() {
      let params = {
        yzTaskMainId: this.detail.id,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/partakeTaskMain", this.$common.getFormData(params))
        .then((res) => {
          if (res.code == "1") {
            this.$api
              .post(
                "/api/v1/updateAssigmentCountCountAddOne",
                this.$common.getFormData({ id: this.detail.id })
              )
              .then((res) => console.log(res.message));
            this.$emit("inits");
            this.$message({
              message: res.message,
              type: "success",
              duration: 1500,
              onClose: () => {
                $("body,html").animate({
                  scrollTop: $(".offer").offset().top - 180,
                });
              },
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
    },
    goChat() {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.huixianggongye.com/f/contactKefu?company_id=" + this.detail.id;
    },
  },
};
</script>
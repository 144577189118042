var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-supplier-list"},[_c('div',{staticClass:"content"},[_vm._m(0),(_vm.list.length)?_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"item-t"},[_c('el-image',{attrs:{"src":item.yzViewShopInfo.logo,"fit":"cover","lazy":""},on:{"click":function($event){return _vm.goDetail(item)}}}),_c('div',{staticClass:"t-info",on:{"click":function($event){return _vm.goDetail(item)}}},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(item.yzViewShopInfo.name))]),_vm._l((item.yzViewShopInfo.yzCompanyAuthItems),function(items){return _c('span',{key:items,staticClass:"common-label"},[_vm._v(_vm._s(_vm.yzCompanyAuthItemsJson[items]))])})],2),_c('div',{staticClass:"line"},[_c('p',[_vm._v(" 已服务企业："),_c('span',[_vm._v(_vm._s(item.yzViewShopInfo.orderTotalCount)+"家")])]),_c('p',[_vm._v(" 成交额："),_c('span',[_vm._v("￥"+_vm._s(item.yzViewShopInfo.alerdyServiceTotalMoney))])])]),_c('div',{staticClass:"desc"},[_vm._v(" 擅长："+_vm._s(item.yzViewShopInfo.hobbyname)+"。 ")])]),(item.isSelect == 1)?_c('div',{staticClass:"btn"},[(
                _vm.isUser &&
                (!_vm.detail.yzViewShopInfo || !_vm.detail.yzViewShopInfo.cropName)
              )?_c('p',{staticClass:"common-btn",on:{"click":function($event){return _vm.checkUser(item)}}},[_vm._v(" 选TA中标 ")]):_vm._e(),(
                _vm.isUser &&
                (!_vm.detail.yzViewShopInfo || !_vm.detail.yzViewShopInfo.cropName)
              )?_c('p',{staticClass:"common-btn",on:{"click":function($event){return _vm.outUser(item)}}},[_vm._v(" 淘汰TA ")]):_vm._e()]):_vm._e(),(item.isSelect == 2)?_c('div',{staticClass:"btn solo"},[(_vm.isUser)?_c('p',{staticClass:"common-btn",on:{"click":function($event){return _vm.closeUser(item)}}},[_vm._v(" 取消中标 ")]):_vm._e()]):_vm._e(),(item.isSelect == 2)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/public/icon_yzb.png"),"alt":""}})]):_vm._e(),(item.isSelect == 3)?_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/images/public/icon_tt.png"),"alt":""}})]):_vm._e(),(
              item.isSelect == 4 ||
              item.isSelect == 5 ||
              item.isSelect == 6 ||
              item.isSelect == 0
            )?_c('div',{staticClass:"icon"}):_vm._e(),_vm._m(1,true)],1),(
            item.singilePrice &&
            ((_vm.userInfo.userCode == item.updateBy && _vm.roleCode!=1) || _vm.roleCode==1) &&
            _vm.userInfo.userCode
          )?_c('div',{staticClass:"else"},[_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("总金额")]),_c('p',[_vm._v("￥"+_vm._s(item.singilePrice))])]),_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("工期")]),_c('p',[_vm._v(_vm._s(item.days)+" "),_c('span',[_vm._v("天")])])]),_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("是否含税")]),_c('p',[_vm._v(_vm._s(item.isFax == 1 ? "是" : "否"))])]),_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("税点")]),_c('p',[_vm._v(_vm._s(item.faxValue || 0)+" "),_c('span',[_vm._v("%")])])]),_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("含税金额")]),_c('p',[_vm._v("￥"+_vm._s(item.totalPrice))])]),_c('div',{staticClass:"else-item"},[_c('h3',[_vm._v("报价单")]),_c('a',{attrs:{"href":item.files,"target":"_blank","rel":"点击下载报价单"}},[_vm._v("点击下载报价单")])])]):_vm._e()])}),0):_vm._e(),(!_vm.list.length)?_c('div',{staticClass:"not-data"},[_vm._v("暂无服务商投标")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-title"},[_c('h3',[_vm._v("服务商列表 "),_c('span',[_vm._v("SERVICES")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.huixianggongye.com/f/contactKefu?toUser=0_yizao004_d4br","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"iconfont icon-liaotian"})])
}]

export { render, staticRenderFns }
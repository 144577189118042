var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.detail.currentShoperYzTaskEvaluation)?_c('div',{staticClass:"offer"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"offer-main"},[_c('el-form',{ref:"offerForm",staticClass:"offer-form",attrs:{"model":_vm.offerForm,"label-position":"top","size":"small"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 3}},[_c('el-form-item',{attrs:{"prop":"singilePrice","label":"总金额（元）","rules":[
                { required: true, message: '请输入总金额', trigger: 'blur' },
              ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入总金额"},model:{value:(_vm.offerForm.singilePrice),callback:function ($$v) {_vm.$set(_vm.offerForm, "singilePrice", $$v)},expression:"offerForm.singilePrice"}})],1)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 3}},[_c('el-form-item',{attrs:{"prop":"days","label":"工期（天）","rules":[
                { required: true, message: '请输入工期', trigger: 'blur' },
              ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入工期"},model:{value:(_vm.offerForm.days),callback:function ($$v) {_vm.$set(_vm.offerForm, "days", $$v)},expression:"offerForm.days"}})],1)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 4 : 2}},[_c('el-form-item',{staticStyle:{"text-align":"center"},attrs:{"prop":"isFax","label":"含税"}},[_c('el-checkbox',{model:{value:(_vm.offerForm.isFax),callback:function ($$v) {_vm.$set(_vm.offerForm, "isFax", $$v)},expression:"offerForm.isFax"}})],1)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 3}},[_c('el-form-item',{attrs:{"prop":"faxValue","label":"税点（%）","rules":[
                {
                  required: _vm.offerForm.isFax,
                  message: '请输入税点',
                  trigger: 'blur',
                },
              ]}},[_c('el-input',{attrs:{"type":"number","disabled":!_vm.offerForm.isFax,"placeholder":"请输入税点"},model:{value:(_vm.offerForm.faxValue),callback:function ($$v) {_vm.$set(_vm.offerForm, "faxValue", $$v)},expression:"offerForm.faxValue"}})],1)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 3}},[_c('el-form-item',{attrs:{"prop":"totalPrice","label":"含税金额（元）"}},[_c('el-input',{attrs:{"type":"number","readonly":"","placeholder":"含税金额"},model:{value:(_vm.totalPrice),callback:function ($$v) {_vm.totalPrice=$$v},expression:"totalPrice"}})],1)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 5}},[_c('el-form-item',{attrs:{"prop":"filesName","label":"上传报价"}},[_c('template',{slot:"label"},[_vm._v(" 上传报价 "),_c('a',{staticClass:"link",attrs:{"href":"http://","target":"_blank","download":"《报价模板》.doc"}},[_vm._v("下载《报价模板》")])]),_c('upload',{ref:"upload",attrs:{"mode":"btn","format":"doc/docx/xls/xlsx/pdf/jpg/png/svg","bizKey":_vm.bizKey,"fileName":_vm.offerForm.filesName,"multiple":false},on:{"update:fileName":function($event){return _vm.$set(_vm.offerForm, "filesName", $event)},"update:file-name":function($event){return _vm.$set(_vm.offerForm, "filesName", $event)},"success":_vm.upSuccess}})],2)],1),_c('el-col',{attrs:{"span":_vm.isWap ? 24 : 5}},[_c('el-form-item',{staticClass:"btn",attrs:{"label":"操作","prop":"price"}},[_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("提交报价")]),_c('el-button',{attrs:{"icon":"el-icon-star-off","loading":_vm.loading2},on:{"click":_vm.goStar}},[_vm._v("收藏")])],1)],1)],1)],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="step-two">
    <div class="steps">
      <el-steps :active="stepTwoActive" align-center>
        <el-step
          v-for="item in stepTwoList"
          :key="item.id"
          :title="contentJson[item.workContent] || item.workContent"
        ></el-step>
      </el-steps>
    </div>
    <div
      class="table-list"
      v-if="
        (hasPay && hasShop && objItem.id) || !hasPay || (hasPay && !objItem.id)
      "
    >
      <el-table
        v-loading="tableLoad"
        :data="tableList"
        border
        size="small"
        style="width: 100%"
      >
        <el-table-column prop="date" align="center" label="项目进度">
          <template slot-scope="scope">
            {{ stepJson[scope.row.step] || scope.row.step }}
          </template>
        </el-table-column>
        <el-table-column prop="workContent" align="center" label="项目内容">
          <template slot-scope="scope">
            {{ contentJson[scope.row.workContent] || scope.row.workContent }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="阶段审核">
          <template slot-scope="scope">
            <upload
              v-if="hasShop && scope.row.commitMoney == 0"
              ref="upload"
              mode="craft"
              btnText="上传审核资料"
              format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
              uploadType="all"
              :keyName="scope.row.id"
              @checkSuccess="checkSuccess"
              @success="upSuccess"
            />
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listResultFiles ||
                  !scope.row.listResultFiles.length) &&
                !hasShop &&
                scope.row.commitMoney == 0
              "
            >
              服务商未上传
            </p>
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.listResultFiles &&
                scope.row.listResultFiles.length &&
                !hasShop &&
                scope.row.commitMoney == 0
              "
            >
              <el-table :data="scope.row.listResultFiles" height="260">
                <el-table-column
                  width="200"
                  property="fileName"
                  label="文件名称"
                ></el-table-column>
                <el-table-column width="100" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      @click="goDown(scope.row)"
                      size="mini"
                      icon="el-icon-download"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                slot="reference"
                icon="el-icon-download"
                type="primary"
                size="mini"
                >下载审核资料</el-button
              >
            </el-popover>
            <p v-if="scope.row.commitMoney != 0">—</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          width="180"
          align="center"
          label="问题反馈"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.commitMoney != 0">—</p>
            <upload
              v-if="!hasShop && scope.row.commitMoney == 0"
              ref="upload"
              mode="craft"
              btnText="请上传反馈资料"
              format="stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx/jpg/png/svg/mp4/avi"
              uploadType="all"
              :keyName="scope.row.id"
              @checkSuccess="checkSuccess"
              @success="upSuccess"
            />
            <p
              class="step-status s5"
              v-if="
                (!scope.row.listQuestionFiles ||
                  !scope.row.listQuestionFiles.length) &&
                hasShop &&
                scope.row.commitMoney == 0
              "
            >
              需求方未上传
            </p>
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.listQuestionFiles &&
                scope.row.listQuestionFiles.length &&
                hasShop &&
                scope.row.commitMoney == 0
              "
            >
              <el-table :data="scope.row.listQuestionFiles" height="260">
                <el-table-column
                  width="200"
                  property="fileName"
                  label="文件名称"
                ></el-table-column>
                <el-table-column width="100" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      @click="goDown(scope.row)"
                      size="mini"
                      icon="el-icon-download"
                      >下载</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                slot="reference"
                icon="el-icon-download"
                type="primary"
                size="mini"
                >下载反馈资料</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="workContent" align="center" label="支付金额">
          <template slot-scope="scope">
            <span class="price">￥{{ scope.row.commitMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="状态确认">
          <template slot-scope="scope">
            <p
              :class="[
                'step-status',
                scope.row.taskUserStatus == 1 ? 's1' : 's5',
              ]"
            >
              {{
                scope.row.taskUserStatus == 1
                  ? "已确认"
                  : scope.row.taskUserStatus == 3
                  ? "审核中"
                  : "未确认"
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" align="center" label="预计完成时间">
          <template slot-scope="scope">
            <p v-if="scope.row.endTime">
              {{ scope.row.endTime.split(" ")[0] }}
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pay-main" v-if="hasPay && !hasShop && objItem.id">
      <pay
        ref="pay"
        @success="paySuccess"
        :yzTaskMainId="yzTaskMainId"
        :objItem="objItem"
        @offlineSuccess="offlineSuccess"
      />
    </div>
    <div class="table-list order-list" v-if="hasPay && !hasShop && objItem.id">
      <el-table
        v-loading="tableLoad"
        :data="tableList2"
        border
        size="small"
        style="width: 100%"
      >
        <el-table-column
          prop="payTime"
          align="center"
          label="支付时间"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.payTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="workContent"
          width="100"
          align="center"
          label="支付金额"
        >
          <template slot-scope="scope">
            <span class="price">￥{{ scope.row.payCash }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="workContent"
          width="100"
          align="center"
          label="优惠券价格"
        >
          <template slot-scope="scope">
            <span class="price">￥{{ scope.row.couponPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="workContent"
          width="100"
          align="center"
          label="实付金额"
        >
          <template slot-scope="scope">
            <span class="price">￥{{ scope.row.realCash }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="workContent"
          width="120"
          align="center"
          label="审核状态"
        >
          <template slot-scope="scope">
            <p :class="['step-status', payClassJson[scope.row.yzPayStatus]]">
              {{ yz_pay_status[scope.row.yzPayStatus] }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          align="center"
          label="备注"
        ></el-table-column>
      </el-table>
    </div>
    <div
      class="f-btn"
      v-if="
        objItem.id &&
        hasPay &&
        objItem.taskUserStatus != 1 &&
        objItem.taskUserStatus != 3 &&
        hasShop
      "
    >
      <el-button
        type="success"
        size="small"
        icon="el-icon-message"
        :loading="load2"
        @click="goPay"
        >提示客户付款</el-button
      >
    </div>
    <div class="f-btn" v-if="commonStatus.currentYzTaskOrderPlan && !hasPay">
      <el-button
        type="success"
        size="small"
        icon="el-icon-message"
        v-if="hasShop"
        :loading="load2"
        @click="goTip"
        >提示客户确认</el-button
      >
      <el-button
        type="primary"
        size="small"
        icon="el-icon-video-play"
        v-if="hasShop"
        :loading="load1"
        @click="goNext"
        >申请进入下一环节</el-button
      >
      <el-button
        type="primary"
        size="small"
        icon="el-icon-message"
        v-if="!hasShop"
        :loading="load2"
        @click="goTip"
        >提示工厂上传阶段成果</el-button
      >
      <el-button
        type="success"
        size="small"
        icon="el-icon-circle-check"
        v-if="!hasShop"
        :loading="load1"
        @click="goNext"
        >确认执行</el-button
      >
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
import pay from "@/components/workbench/pay";
export default {
  components: { upload, pay },
  data() {
    return {
      stepTwoActive: 0,
      tableList: [],
      stepJson: {},
      statusJson: {},
      commonStatus: { currentYzTaskOrderPlan: {} },
      stepTwoList: [],
      tableLoad: false,
      load1: false,
      load2: false,
      contentJson: {},
      contentList: [],
      objItem: {},
      tableList2: [],
      yz_pay_status: {},
      payClassJson: {
        1: "s5",
        2: "s2",
        3: "s1",
        4: "s3",
        5: "s5",
      },
      loadUps:{}
    };
  },
  props: {
    selectShoperYzTaskEvaluation: {
      default() {
        return {};
      },
      type: Object,
    },
    yzTaskMainId: {
      default: "",
      type: Number | String,
    },
  },
  computed: {
    hasPay() {
      return this.objItem.commitMoney != 0;
    },
    hasShop() {
      return (
        this.selectShoperYzTaskEvaluation.createBy ==
        this.$store.state.userInfo.userCode
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    //项目内容
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_content.json")
      .then((res) => {
        this.contentList = res;
        res.forEach((item) => {
          this.contentJson[item.dictValue] = item.dictLabel;
        });
      });
    //支付进度
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_type.json")
      .then((res) => {
        res.forEach((item) => {
          this.stepJson[item.dictValue] = item.dictLabel;
        });
        this.inits();
      });
    //付款状态
    this.$sapi.get("/api/v1/getDictDataList-yz_pay_status.json").then((res) => {
      res.forEach((item) => {
        this.yz_pay_status[item.dictValue] = item.dictLabel;
      });
    });
    //阶段状态
    this.$sapi
      .get("/api/v1/getDictDataList-yz_task_order_plan_operate_status.json")
      .then((res) => {
        res.forEach((item) => {
          this.statusJson[item.dictValue] = item.dictLabel;
        });
      });
  },
  methods: {
    offlineSuccess() {
      this.getOrderList();
    },
    paySuccess() {
      this.inits(true);
    },
    goDown(row) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `${window.config.api}/a/file/download/${row.id}`;
    },
    goPay() {
      this.load2 = true;
      this.$api
        .post(
          "/api/v1/sendPayTaskMainMessage",
          this.$common.getFormData({
            taskId: this.yzTaskMainId,
            orderPlanId: this.objItem.id,
          })
        )
        .then((res) => {
          this.load2 = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 2000,
          });
          this.inits(true);
        })
        .catch((err) => {
          this.load2 = false;
        });
    },
    goNext() {
      this.$confirm(
        `您确认${this.hasShop ? "申请进入下一环节" : "执行下一环节"}吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.load1 = true;
        let params = {
          yzTaskMainId: this.yzTaskMainId,
        };
        if (this.hasShop) {
          params.yzTaskOrderPlanId = this.objItem.id;
        } else {
          params.currentTaskPlanId = this.objItem.id;
        }
        this.$api
          .post(
            this.hasShop
              ? "/api/v1/sendYzTaskOrderPlanDoingNextMessage"
              : "/api/v1/taskUserMakeSureRunCurrentTaskOrderPlan",
            this.$common.getFormData(params)
          )
          .then((res) => {
            this.load1 = false;
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 2000,
            });
            this.inits(true);
          })
          .catch((err) => {
            this.load1 = false;
          });
      });
    },
    goTip() {
      this.load2 = true;
      let params = {
        yzTaskMainId: this.yzTaskMainId,
        yzTaskOrderPlanId: this.objItem.id,
      };
      this.$api
        .post(
          this.hasShop
            ? "/api/v1/sendYzTaskOrderPlanQualityMessage"
            : "/api/v1/sendYzTaskOrderPlanDoingUploadFileMessage",
          this.$common.getFormData(params)
        )
        .then((res) => {
          this.load2 = false;
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 2000,
          });
        })
        .catch((err) => {
          this.load2 = false;
        });
    },
    inits(next) {
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlanStatistics",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            this.commonStatus = res.data;
            this.objItem = this.commonStatus.currentYzTaskOrderPlan || {};
            if (this.objItem.id) {
              this.getOrderList();
            }
            this.getTablelist(next);
          }
        });
    },
    getOrderList() {
      this.tableLoad = true;
      this.$api
        .get(
          "/api/v1/getCurrentOrderPlayPayList?taskOrderPlanId=" +
            this.objItem.id
        )
        .then((res) => {
          this.tableLoad = false;
          this.tableList2 = res.data;
        });
    },
    getTablelist(next) {
      this.tableLoad = true;
      this.$api
        .post(
          "/api/v1/getListTaskOrderPlan",
          this.$common.getFormData({
            yzTaskMainId: this.yzTaskMainId,
          })
        )
        .then((res) => {
          this.tableLoad = false;
          this.stepTwoList = res.data;
          if (this.objItem.id) {
            this.tableList = res.data.filter((item, index) => {
              if (item.id == this.objItem.id) {
                this.stepTwoActive = index + 1;
              }
              return item.id == this.objItem.id;
            });
          } else {
            this.stepTwoActive = res.data.length;
            this.tableList = res.data;
            next ? this.$emit("getNewData", !next) : null;
          }
          if (this.$refs.pay) {
            this.$refs.pay.inits();
          }
        });
    },
    checkSuccess(files) {
      if (files) {
        this.$refs.upload.submit();
      }
    },
    goAbout(files, i = 0) {
      this.$api
        .post(
          this.hasShop
            ? "/api/v1/uploadYzTaskContractResultFile"
            : "/api/v1/uploadTaskOrderPlanQuestionFile",
          this.$common.getFormData({
            fileUploadId: files[i].fileUploadId,
            yzTaskContractId: this.yzTaskMainId,
            yzTaskOrderPlanId: files[i].keyName,
          })
        )
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.$message({
                message: "上传资料成功！",
                type: "success",
                duration: 1500,
              });
              this.loadUps.close ? this.loadUps.close() : null;
              this.inits();
            } else {
              i++;
              //未结束，继续关联
              this.goAbout(files, i);
            }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 1500,
            });
            this.loadUps.close ? this.loadUps.close() : null;
          }
        })
        .catch((err) => {
          this.loadUps.close ? this.loadUps.close() : null;
          this.$message({
            message: err,
            type: "error",
            duration: 1500,
          });
        });
    },
    upSuccess(files) {
      if (files) {
        this.loadUps = this.$message({
          message: "正在处理文件，请不要关闭页面",
          iconClass: "el-icon-loading",
          type: "success",
          duration: 0,
        });
        this.goAbout(files);
      }
    },
  },
};
</script>